<template>
    <div class="main-container">
        <div class="button-container">
            <div>
                <button class="btn btn-primary" @click="onAjouterClick">Ajouter</button>
            </div>
        </div>
        <ag-grid-vue style="width: 100%; height: calc(100vh - 100px);" class="ag-theme-alpine" :columnDefs="columnDefs"
            :rowData="rowData" :defaultColDef="defaultColDef" @grid-ready="onGridReady" rowModelTypes="infinite"
            :grid-options="gridOptions" />
    </div>
</template>
<style lang="scss" scoped></style>
<script lang="ts">
import { defineComponent } from "vue"
import { AgGridVue } from "ag-grid-vue3"
import axios from "axios"
import Tab from "../tab"
import gridButtons from "./composants/grid-buttons.vue"
import Reglement, { ReglementGrid, Statut } from "../reglement"
import DialogParams, { DialogButtons } from "../dialog-params"
import { validerAjout } from "../validate-reglement"
import Helpers from "../helpers"

export default defineComponent({
    props: {
        setTab: { type: Function, required: true },
        showDialog: { type: Function, required: true }
    },
    data() {
        return {
            reglements: [] as Reglement[],
            columnDefs: [
                { headerName: "Numéro", field: "numero", flex: 1 },
                { headerName: "Objet", field: "objet", flex: 2 },
                { headerName: "Statut", field: "statut", width: 175 },
                { headerName: "Répondant", field: "repondant", flex: 1 },
                { headerName: "Dernière mise à jour", field: "dateModification", width: 175 },
                {
                    cellRenderer: gridButtons,
                    cellRendererParams: {
                        supprime: (reglement: ReglementGrid) => {
                            this.showDialog({
                                innerText: `Êtes-vous certains de vouloir supprimer le règlement ${reglement.numero}?`,
                                title: "Supprimer",
                                confirm: () => {
                                    axios.delete(window.location.href + "api/" + reglement._id).then(() => this.loadGridData())
                                }
                            } as DialogParams)
                        },
                        edit: this.edit
                    },
                    cellClass: 'grid-buttons',
                    width: 120
                }
            ],
            rowData: [],
            defaultColDef: {
                sortable: true,
                wrapText: true,
                autoHeight: true,
                wrapHeaderText: true,
                autoHeaderHeight: true,
                resizable: false
            },
            gridOptions: {
                onRowDoubleClicked: (params: { data: Reglement }) => {
                    this.edit(params.data)
                }
            },
            gridApi: null,
            columnApi: null
        }
    },
    components: {
        AgGridVue
    },
    methods: {
        onGridReady(params: any) {
            this.gridApi = params.api
            this.columnApi = params.columnApi
            this.loadGridData()
        },
        loadGridData() {
            axios.get(window.location.href + "api").then(data => {
                const dataTable: ReglementGrid[] = []
                for (const re of (data.data as Reglement[])) {
                    Helpers.updateReglementMissingFields(re)
                    dataTable.push({
                        _id: re._id,
                        numero: re.infosLegales.numero,
                        objet: re.infosLegales.objet.substring(0, 200) + (re.infosLegales.objet.length > 200 ? "..." : ""),
                        statut: re.statut,
                        repondant: `${re.entite.repondant.prenom || ""} ${re.entite.repondant.nom || ""}`,
                        dateModification: new Date(re.dateModification).toLocaleDateString('fr-CA')
                    })
                }
                this.gridApi.setGridOption('rowData', dataTable)
                this.reglements = data.data
            })
        },
        onAjouterClick() {
            const messageValidation = validerAjout(this.reglements)
            if (messageValidation) {
                this.showDialog({
                    title: "Ajouter",
                    innerText: messageValidation,
                    dialogButtons: DialogButtons.Ok
                } as DialogParams)
            } else {
                this.setTab(Tab.Formulaire, {
                    statut: Statut.EnPreparation,
                    reglementModifieUnAutre: false
                })
            }
        },
        edit(reglement: Reglement) {
            this.setTab(Tab.Formulaire, this.reglements.find((r) => r._id === reglement._id))
        }
    }
})
</script>
