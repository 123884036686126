<template>
    <input type="number" :value="champDisplay" :readonly="readonly" :id="id" class="form-control input-print-keep-margin"
        @change="onChange" />
</template>
<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
    props: {
        parent: { type: Object, required: true },
        champ: { type: String, required: true },
        readonly: { type: Boolean, required: true },
        id: { type: String, required: false },
        onInput: { type: Function as any, required: false }
    },
    data() {
        return {
            champDisplay: (this.parent[this.champ] || 0).toFixed(2)
        }
    },
    methods: {
        onChange(event: InputEvent) {
            this.parent[this.champ] = Number((event.target as HTMLInputElement).value)
            this.champDisplay = this.parent[this.champ].toFixed(2)
            this.onInput && this.onInput()
        }
    },
    watch: {
        parent: {
            handler() {
                this.champDisplay = (this.parent[this.champ] || 0).toFixed(2)
            },
            deep: true
        }
    }
})
</script>