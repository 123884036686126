import Reglement, { Statut } from "./reglement"

export default {
    allowOnlyNumbers(e: KeyboardEvent) {
        if (!e.key.match(/[0-9]/)) {
            e.preventDefault()
        }
    },
    updateReglementMissingFields(reglement: Reglement) {
        // KEEP this at ALL TIMES!
        if (!reglement.infosLegales) {
            reglement.infosLegales = {} as any
        }

        if (!reglement.entite) {
            reglement.entite = {} as any
        }

        if (!reglement.entite.repondant) {
            reglement.entite.repondant = {} as any
        }

        if (!reglement.entite.personneRessource) {
            reglement.entite.personneRessource = {} as any
        }

        if (!reglement.approbationPHV) {
            reglement.approbationPHV = {} as any
        }

        if (!reglement.evenements) {
            reglement.evenements = {} as any
        }

        if (!reglement.statut || !Object.values(Statut).includes(reglement.statut)) {
            reglement.statut = Statut.Annule
        }

        if (!reglement.documents) {
            reglement.documents = {} as any
        }

        if (!reglement.financieres) {
            reglement.financieres = {} as any
        }

        if (!reglement.financieres.depenses) {
            reglement.financieres.depenses = {} as any
        }

        if (!reglement.financieres.credits) {
            reglement.financieres.credits = {} as any
        }

        if (!reglement.financieres.montantsApresApprobation) {
            reglement.financieres.montantsApresApprobation = {} as any
        }

        if (!reglement.repartitionFiscale) {
            reglement.repartitionFiscale = {} as any
        }

        if (!reglement.repartitionFiscale.annuite) {
            reglement.repartitionFiscale.annuite = [{}] as any
        }

        if (!reglement.repartitionFiscale.fardeauFiscalEmprunt) {
            reglement.repartitionFiscale.fardeauFiscalEmprunt = [{}] as any
        }

        if (!reglement.repartitionFiscale.fardeauFiscalActuel) {
            reglement.repartitionFiscale.fardeauFiscalActuel = {} as any
        }

        if (!reglement.repartitionFiscale.fardeauFiscalActuel.taxeGenerale) {
            reglement.repartitionFiscale.fardeauFiscalActuel.taxeGenerale = {} as any
        }

        if (!reglement.repartitionFiscale.fardeauFiscalActuel.tarification) {
            reglement.repartitionFiscale.fardeauFiscalActuel.tarification = {} as any
        }

        if (!reglement.repartitionFiscale.fardeauFiscalActuel.autresTaxes) {
            reglement.repartitionFiscale.fardeauFiscalActuel.autresTaxes = {} as any
        }

        if (!reglement.attestation) {
            reglement.attestation = {} as any
        }

        if (!reglement.attestation.personne) {
            reglement.attestation.personne = {} as any
        }
    },
    getNbSignaturesRequises(reglement: Reglement) {
        if (reglement.approbationPHV.nbPHVNécessaires <= 25) {
            return Math.ceil(reglement.approbationPHV.nbPHVNécessaires / 2)
        }
        else {
            return Math.ceil((reglement.approbationPHV.nbPHVNécessaires - 25) * 0.1 + 13)
        }
    },
    getNbJrsRegistre(reglement: Reglement) {
        if (reglement.approbationPHV.nbPHVNécessaires >= 1 && reglement.approbationPHV.nbPHVNécessaires <= 999) {
            return 1
        }
        else if (reglement.approbationPHV.nbPHVNécessaires >= 1000 && reglement.approbationPHV.nbPHVNécessaires <= 1499) {
            return 2
        }
        else if (reglement.approbationPHV.nbPHVNécessaires >= 1500 && reglement.approbationPHV.nbPHVNécessaires <= 1999) {
            return 3
        }
        else if (reglement.approbationPHV.nbPHVNécessaires >= 2000 && reglement.approbationPHV.nbPHVNécessaires <= 2499) {
            return 4
        }
        else if (reglement.approbationPHV.nbPHVNécessaires >= 2500) {
            return 5
        }
    },
    pacques(annee: number) {
        // http://revue.sesamath.net/IMG/pdf/calcul_de_paques.pdf
        const n = annee - 1900
        const a = n % 19
        const b = Math.floor((7 * a + 1) / 19)
        const c = (11 * a - b + 4) % 29
        const d = Math.floor(n / 4)
        const e = (n - c + d + 31) % 7
        const date = 25 - c - e
        if (date > 0) {
            return new Date(`${annee}-04-${date}`)
        } else {
            return new Date(`${annee}-03-${31 + date}`)
        }
    },
    isJourFerie(date: Date) {
        const datePacques = new Date(this.pacques(date.getFullYear()))
        const vendrediSaint = new Date(new Date(datePacques).setDate(datePacques.getDate() - 2))
        const lundiSaint = new Date(new Date(datePacques).setDate(datePacques.getDate() + 1))

        // Lundi avant le 25 mai
        const mai25 = new Date(`${date.getFullYear()}-05-25 `)
        let jourPatriote: Date
        const mai25Day = mai25.getDay()
        if (mai25Day === 1) {
            jourPatriote = mai25
        } else if (mai25Day === 0) {
            jourPatriote = new Date(new Date(mai25).setDate(mai25.getDate() - 6))
        } else {
            jourPatriote = new Date(new Date(mai25).setDate(mai25.getDate() - (mai25Day - 1)))
        }

        // Premier lundi de septembre
        const sept1 = new Date(`${date.getFullYear()}-09-01 `)
        const sept1Day = sept1.getDay()
        let feteTravail: Date
        if (sept1Day === 1) {
            feteTravail = sept1
        } else if (sept1Day === 0) {
            feteTravail = new Date(new Date(sept1).setDate(sept1.getDate() + 1))
        } else {
            feteTravail = new Date(new Date(sept1).setDate(sept1.getDate() + (sept1Day - 1)))
        }

        // Deuxième lundi d'octobre
        const oct7 = new Date(`${date.getFullYear()}-10-07 `)
        const oct7Day = oct7.getDay()
        let actionGrace: Date
        if (oct7Day === 1) {
            actionGrace = oct7
        } else if (oct7Day === 0) {
            actionGrace = new Date(new Date(oct7).setDate(sept1.getDate() + 1))
        } else {
            actionGrace = new Date(new Date(oct7).setDate(sept1.getDate() + (oct7Day - 1)))
        }

        if (
            (date.getDate() === vendrediSaint.getDate() && date.getMonth() === vendrediSaint.getMonth()) ||
            (date.getDate() === lundiSaint.getDate() && date.getMonth() === lundiSaint.getMonth()) ||
            (date.getDate() === jourPatriote.getDate() && date.getMonth() === jourPatriote.getMonth()) ||
            (date.getDate() === feteTravail.getDate() && date.getMonth() === feteTravail.getMonth()) ||
            (date.getDate() === actionGrace.getDate() && date.getMonth() === actionGrace.getMonth()) ||
            (date.getDate() === 1 && date.getMonth() === 0) || // 1 janvier
            (date.getDate() === 2 && date.getMonth() === 0) || // 2 janvier
            (date.getDate() === 24 && date.getMonth() === 5) || // 24 juin
            (date.getDate() === 1 && date.getMonth() === 6) || // 1 juillet
            (date.getDate() === 24 && date.getMonth() === 11) || // 24 décembre
            (date.getDate() === 25 && date.getMonth() === 11) || // 25 décembre
            (date.getDate() === 26 && date.getMonth() === 11) || // 26 décembre
            (date.getDate() === 31 && date.getMonth() === 11) // 31 décembre
        ) {
            return true
        }
    },
    getDateMaxOuvrable(date: string, nbJours: number, asc: boolean = true) {
        let jourNo = 0
        let currentMaxDate = new Date(`${date} `)
        do {
            currentMaxDate.setDate(currentMaxDate.getDate() + (asc ? 1 : -1))
            if (currentMaxDate.getDay() !== 0 && currentMaxDate.getDay() !== 6 && !this.isJourFerie(currentMaxDate)) {
                jourNo++
            }
        } while (jourNo < nbJours)
        return currentMaxDate
    },
    getDateMax(date: string, nbJours: number) {
        const currentMaxDate = new Date(`${date} `)
        currentMaxDate.setDate(currentMaxDate.getDate() + nbJours)
        return currentMaxDate
    },
    formatDateTexte(date: Date) {
        return date.toLocaleDateString("fr-CA", { year: "numeric", month: "long", day: "numeric" })
    }
}