<template>
    <div class="main-container" @keydown.ctrl.83="save" ref="top" @unload="onRetourListe">
        <div class="button-container">
            <button class="btn btn-primary" @click="onRetourListe">Retour à la liste</button>
            <div>
                <button v-if="reglement.statut === Statut.Annule" class="btn btn-primary" @click="annuler">Modifier le
                    règlement</button>
                <button v-if="reglement.statut === Statut.EnPreparation || reglement.statut === Statut.Termine"
                    class="btn btn-primary" @click="annuler">Annuler le règlement</button>
                <button v-if="reglement.statut === Statut.EnPreparation" class="btn btn-primary"
                    @click="valider">Valider</button>
                <button class="btn btn-primary" @click="imprimer">Imprimer</button>
                <button class="btn btn-primary" @click="save" :disabled="isSaveDisabled()">Sauvegarder</button>
            </div>
        </div>
        <div v-if="messagesValidation && messagesValidation.length" class="alert alert-danger" role="alert">
            <div v-for="validationGroup in messagesValidation">
                <p>{{ validationGroup.titre }}</p>
                <ul>
                    <li v-for="message in validationGroup.validations">{{ message }}.</li>
                </ul>
            </div>
        </div>
        <div v-if="messagesValidation && !messagesValidation.length" class="alert alert-success" role="alert">
            Le règlement d'emprunt est valide et prêt à être envoyé au Ministère.
            <button v-if="reglement.statut === Statut.EnPreparation" class="btn btn-primary"
                @click="terminer">Terminer</button>
        </div>
        <div class="form">
            <Attestation v-if="reglement.statut === Statut.Termine || reglement.statut === Statut.TransmisMinistere"
                :reglement="reglement" :forced-opened="isForcedOpened()" :save="save" :class="isReadOnly()"
                class="print-break-after"></Attestation>
            <InfosLegales :reglement="reglement" :forced-opened="isForcedOpened()" :tried-save="triedSave"
                :readonly="isReadOnly()" class="print-break-after" />
            <Entite :reglement="reglement" :forced-opened="isForcedOpened()" :readonly="isReadOnly()" />
            <Documents :reglement="reglement" :forced-opened="isForcedOpened()" :readonly="isReadOnly()"
                :showDialog="showDialog" :changements-documents="changementsDocuments" class="print-break-after" />
            <Financieres :reglement="reglement" :forced-opened="isForcedOpened()" :readonly="isReadOnly()"
                class="print-break-after" />
            <RepartitionFiscale v-if="!reglement.entite.codeGeographique?.match(/^[A-Z]/)" :reglement="reglement"
                :forced-opened="isForcedOpened()" :readonly="isReadOnly()" />
            <p class="print">Imprimé le {{ dateActuelle }}</p>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.alert>ul {
    margin-bottom: 0;
}
</style>
<script lang="ts">
import { PropType, defineComponent, nextTick } from 'vue'
import Tab from '../tab'
import Reglement, { Statut } from '../reglement'
import axios from 'axios'
import Entite from "./sections-formulaire/entite.vue"
import InfosLegales from './sections-formulaire/infos-legales.vue'
import { ValidationGroup, isValidForSave, validateAll } from '../validate-reglement'
import Documents from './sections-formulaire/documents.vue'
import Financieres from './sections-formulaire/financieres.vue'
import RepartitionFiscale from './sections-formulaire/repartition-fiscale.vue'
import { ToastStatus } from '../dialog-params'
import Helpers from "../helpers"
import Attestation from './sections-formulaire/attestation.vue'

export default defineComponent({
    props: {
        setTab: { type: Function, required: true },
        showDialog: { type: Function, required: true },
        showToast: { type: Function, required: true },
        reglementGrid: { type: Object as PropType<Reglement>, required: true, readonly: true }
    },
    data() {
        return {
            Statut,
            printing: false,
            reglement: { ...this.reglementGrid },
            reglementSource: JSON.parse(JSON.stringify(this.reglementGrid)),
            triedSave: Boolean(this.reglementGrid._id),
            messagesValidation: undefined as ValidationGroup[],
            changementsDocuments: { nouveaux: [], supprime: [] },
            dateActuelle: new Date().toLocaleString()
        }
    },
    components: {
        Entite,
        InfosLegales,
        Documents,
        Financieres,
        RepartitionFiscale,
        Attestation
    },
    mounted() {
        document.addEventListener("keydown", this.keydown)
        window.onbeforeunload = this.beforeUnload
    },
    beforeUnmount() {
        document.removeEventListener("keydown", this.keydown)
        window.onbeforeunload = undefined
    },
    methods: {
        onRetourListe() {
            if (!this.hasUnsavedChanges() || confirm("Les modifications que vous avez apportées ne seront pas enregistrées.")) {
                this.setTab(Tab.Grid)
            }
        },
        keydown(e: KeyboardEvent) {
            if (e.ctrlKey && e.key === "s") {
                e.preventDefault()
                this.save()
            }
        },
        hasUnsavedChanges() {
            return JSON.stringify(this.reglement) !== JSON.stringify(this.reglementSource)
        },
        beforeUnload() {
            if (this.hasUnsavedChanges()) {
                return "Unsaved changes"
            }
        },
        isSaveDisabled() {
            return !isValidForSave(this.reglement, this.triedSave)
        },
        save() {
            this.triedSave = true
            if (isValidForSave(this.reglement, this.triedSave)) {
                return axios.put(window.location.href + "api", this.reglement).then(async (response) => {
                    Helpers.updateReglementMissingFields(response.data)
                    this.reglement = response.data

                    await axios.post(`${window.location.href}api/${this.reglement._id}/fichiers`, this.changementsDocuments, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(responseDocuments => {
                        this.changementsDocuments.nouveaux = []
                        this.changementsDocuments.supprime = []
                        this.reglement.documents = responseDocuments.data
                        this.reglementSource = JSON.parse(JSON.stringify(this.reglement))
                    })

                    this.showToast({ text: "Le règlement d'emprunt a été sauvegardé avec succès.", status: ToastStatus.Success })
                }).catch(() => {
                    this.showToast({ text: "La sauvegarde a échouée. Veuillez réessayer. Si l'erreur persiste, contacter un administrateur.", status: ToastStatus.Error })
                    throw "Erreur Sauvegarde"
                })
            } else {
                this.showToast({ text: "La sauvegarde a échouée. Veuillez réessayer. Si l'erreur persiste, contacter un administrateur.", status: ToastStatus.Error })
                throw "Règlement invalide"
            }
        },
        imprimer() {
            this.printing = true
            this.dateActuelle = new Date().toLocaleString()
            nextTick(() => {
                print()
                this.printing = false
            })
        },
        isReadOnly() {
            return this.reglement.statut !== Statut.EnPreparation
        },
        isForcedOpened() {
            return this.printing || this.reglement.statut === Statut.Termine
        },
        annuler() {
            if (this.reglement._id) {
                this.reglement.statut = Statut.Annule
                this.save()
            } else {
                this.onRetourListe()
            }
        },
        valider() {
            this.messagesValidation = validateAll(this.reglement)
            this.$refs.top.scrollIntoView({ behavior: "smooth" })
        },
        terminer() {
            if (validateAll(this.reglement).length) {
                this.valider()
            } else {
                this.reglement.statut = Statut.Termine
                this.messagesValidation = undefined
                try {
                    this.save()
                } catch (error) {
                    this.reglement.statut = Statut.EnPreparation
                }
            }
        }
    }
})
</script>