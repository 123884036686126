import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9e5d0c10"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-container" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_4 = {
  key: 1,
  class: "alert alert-success",
  role: "alert"
}
const _hoisted_5 = { class: "form" }
const _hoisted_6 = { class: "print" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Attestation = _resolveComponent("Attestation")!
  const _component_InfosLegales = _resolveComponent("InfosLegales")!
  const _component_Entite = _resolveComponent("Entite")!
  const _component_Documents = _resolveComponent("Documents")!
  const _component_Financieres = _resolveComponent("Financieres")!
  const _component_RepartitionFiscale = _resolveComponent("RepartitionFiscale")!

  return (_openBlock(), _createElementBlock("div", {
    class: "main-container",
    onKeydown: _cache[7] || (_cache[7] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)), ["ctrl"]), ["83"])),
    ref: "top",
    onUnload: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.onRetourListe && _ctx.onRetourListe(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onRetourListe && _ctx.onRetourListe(...args)))
      }, "Retour à la liste"),
      _createElementVNode("div", null, [
        (_ctx.reglement.statut === _ctx.Statut.Annule)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-primary",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.annuler && _ctx.annuler(...args)))
            }, "Modifier le règlement"))
          : _createCommentVNode("v-if", true),
        (_ctx.reglement.statut === _ctx.Statut.EnPreparation || _ctx.reglement.statut === _ctx.Statut.Termine)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "btn btn-primary",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.annuler && _ctx.annuler(...args)))
            }, "Annuler le règlement"))
          : _createCommentVNode("v-if", true),
        (_ctx.reglement.statut === _ctx.Statut.EnPreparation)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: "btn btn-primary",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.valider && _ctx.valider(...args)))
            }, "Valider"))
          : _createCommentVNode("v-if", true),
        _createElementVNode("button", {
          class: "btn btn-primary",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.imprimer && _ctx.imprimer(...args)))
        }, "Imprimer"),
        _createElementVNode("button", {
          class: "btn btn-primary",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
          disabled: _ctx.isSaveDisabled()
        }, "Sauvegarder", 8 /* PROPS */, _hoisted_2)
      ])
    ]),
    (_ctx.messagesValidation && _ctx.messagesValidation.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messagesValidation, (validationGroup) => {
            return (_openBlock(), _createElementBlock("div", null, [
              _createElementVNode("p", null, _toDisplayString(validationGroup.titre), 1 /* TEXT */),
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(validationGroup.validations, (message) => {
                  return (_openBlock(), _createElementBlock("li", null, _toDisplayString(message) + ".", 1 /* TEXT */))
                }), 256 /* UNKEYED_FRAGMENT */))
              ])
            ]))
          }), 256 /* UNKEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.messagesValidation && !_ctx.messagesValidation.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createTextVNode(" Le règlement d'emprunt est valide et prêt à être envoyé au Ministère. "),
          (_ctx.reglement.statut === _ctx.Statut.EnPreparation)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn btn-primary",
                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.terminer && _ctx.terminer(...args)))
              }, "Terminer"))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.reglement.statut === _ctx.Statut.Termine || _ctx.reglement.statut === _ctx.Statut.TransmisMinistere)
        ? (_openBlock(), _createBlock(_component_Attestation, {
            key: 0,
            reglement: _ctx.reglement,
            "forced-opened": _ctx.isForcedOpened(),
            save: _ctx.save,
            class: _normalizeClass([_ctx.isReadOnly(), "print-break-after"])
          }, null, 8 /* PROPS */, ["reglement", "forced-opened", "save", "class"]))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_InfosLegales, {
        reglement: _ctx.reglement,
        "forced-opened": _ctx.isForcedOpened(),
        "tried-save": _ctx.triedSave,
        readonly: _ctx.isReadOnly(),
        class: "print-break-after"
      }, null, 8 /* PROPS */, ["reglement", "forced-opened", "tried-save", "readonly"]),
      _createVNode(_component_Entite, {
        reglement: _ctx.reglement,
        "forced-opened": _ctx.isForcedOpened(),
        readonly: _ctx.isReadOnly()
      }, null, 8 /* PROPS */, ["reglement", "forced-opened", "readonly"]),
      _createVNode(_component_Documents, {
        reglement: _ctx.reglement,
        "forced-opened": _ctx.isForcedOpened(),
        readonly: _ctx.isReadOnly(),
        showDialog: _ctx.showDialog,
        "changements-documents": _ctx.changementsDocuments,
        class: "print-break-after"
      }, null, 8 /* PROPS */, ["reglement", "forced-opened", "readonly", "showDialog", "changements-documents"]),
      _createVNode(_component_Financieres, {
        reglement: _ctx.reglement,
        "forced-opened": _ctx.isForcedOpened(),
        readonly: _ctx.isReadOnly(),
        class: "print-break-after"
      }, null, 8 /* PROPS */, ["reglement", "forced-opened", "readonly"]),
      (!_ctx.reglement.entite.codeGeographique?.match(/^[A-Z]/))
        ? (_openBlock(), _createBlock(_component_RepartitionFiscale, {
            key: 1,
            reglement: _ctx.reglement,
            "forced-opened": _ctx.isForcedOpened(),
            readonly: _ctx.isReadOnly()
          }, null, 8 /* PROPS */, ["reglement", "forced-opened", "readonly"]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("p", _hoisted_6, "Imprimé le " + _toDisplayString(_ctx.dateActuelle), 1 /* TEXT */)
    ])
  ], 544 /* NEED_HYDRATION, NEED_PATCH */))
}