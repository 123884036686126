import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", {
      class: _normalizeClass(["card-header", _ctx.collapsed && !_ctx.forcedOpened ? 'collapsed' : '']),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.collapsed = !_ctx.collapsed))
    }, [
      _createElementVNode("i", {
        class: _normalizeClass(["fa float-end", _ctx.collapsed ? 'fa-chevron-up' : 'fa-chevron-down'])
      }, null, 2 /* CLASS */),
      _createTextVNode(" " + _toDisplayString(_ctx.title), 1 /* TEXT */)
    ], 2 /* CLASS */),
    (!_ctx.collapsed || _ctx.forcedOpened)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["card-body", _ctx.forcedOpened ? 'card-body-forced-open' : ''])
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ]))
}