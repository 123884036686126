<template>
    <div v-if="params.show">
        <div class="modal fade show" :style="params.show ? 'display: block' : ''" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{params.title}}</h5>
                        <i type="button" class="close fa fa-xmark"  @click="cancel" aria-label="Close"></i>
                    </div>
                    <div class="modal-body">
                        <p>{{params.innerText}}</p>
                    </div>
                    <div v-if="params.dialogButtons === DialogButtons.AnnulerContinuer" class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="cancel">Annuler</button>
                        <button type="button" class="btn btn-primary" @click="confirm">Continuer</button>
                    </div>
                    <div v-else-if="params.dialogButtons === DialogButtons.Ok" class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="confirm">Ok</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-backdrop fade show"></div>
    </div>
</template>
<style lang="scss" scoped>
.fa-xmark {
    font-size: 1.5em;
}

.modal-body {
    white-space: pre-wrap;
}
</style>
<script lang="ts">
import { PropType, defineComponent } from 'vue'
import DialogParams, {DialogButtons} from '../../dialog-params'

export default defineComponent({
    props: {
        params: { type: Object as PropType<DialogParams>, required: true }
    },
    data() {
        return {
            DialogButtons
        }
    },
    methods: {
        cancel() {
            this.params.show = false
        },
        confirm() {
            this.params.show = false
            this.params.confirm && this.params.confirm()
        }
    }
})
</script>