import Reglement, { Statut, TitreCivilite, TypeApprobationPHV } from "./reglement"
import * as EmailValidator from "email-validator"
import * as messagesValidation from "./messages/validation.json"
import Helpers from "./helpers"

export interface ValidationGroup {
    titre: string
    validations: string[]
}

export const validationsReglement = {
    infosLegales: {
        objet(reglement: Reglement) {
            return reglement.infosLegales.objet
        },
        numero(reglement: Reglement) {
            return reglement.infosLegales.numero
        }
    },
    entite: {
        repondant: {
            courriel(reglement: Reglement) {
                return !reglement.entite.repondant.courriel || EmailValidator.validate(reglement.entite.repondant.courriel)
            },
        },
        personneRessource: {
            courriel(reglement: Reglement) {
                return !reglement.entite.personneRessource.courriel || EmailValidator.validate(reglement.entite.personneRessource.courriel)
            },
            telephone(reglement: Reglement) {
                return !reglement.entite.personneRessource.telephone || reglement.entite.personneRessource.telephone.match(/^[0-9]{3}\-[0-9]{3}\-[0-9]{4}(\#[0-9]{1,6})?$/)
            }
        },
        courrielTransmission(reglement: Reglement) {
            if (!reglement.entite.courrielTransmission) {
                return true
            }

            const courriels = reglement.entite.courrielTransmission.replace(" ", "").replace(";", ",").split(",")

            return courriels.every(courriel => EmailValidator.validate(courriel))
        }
    },
    attestation: {
        personne: {
            courriel(reglement: Reglement) {
                return !reglement.attestation.personne.courriel || EmailValidator.validate(reglement.attestation.personne.courriel)
            },
            telephone(reglement: Reglement) {
                return !reglement.attestation.personne.telephone || reglement.attestation.personne.telephone.match(/^[0-9]{3}\-[0-9]{3}\-[0-9]{4}(\#[0-9]{1,6})?$/)
            }
        }
    }
}

export function validerAjout(reglements: Reglement[]) {
    if (reglements.length >= 20) {
        return `Vous avez atteint la limite de règlements possibles. Veuillez supprimer un règlement pour pouvoir en créer un autre.
        
        Si ce n’est déjà fait, vous devriez d’abord en conserver une copie. Pour ce faire, accéder d’abord à ce(s) règlement(s), puis télécharger une version « pdf » à l’aide du bouton à cet effet.`
    }

    const dateMaxPreparation = new Date()
    dateMaxPreparation.setMonth(dateMaxPreparation.getMonth() - 3)
    const dateMaxTransmisMinistere = new Date()
    dateMaxTransmisMinistere.setFullYear(dateMaxTransmisMinistere.getFullYear() - 1)

    const aSupprimer: Reglement[] = []

    for (const reglement of reglements) {
        const dateDepensesEngagees = new Date(reglement.attestation.dateDepensesEngagees)
        if ((reglement.statut === Statut.Annule || reglement.statut === Statut.RefuseParScrutin) ||
            (reglement.statut === Statut.TransmisMinistere && dateDepensesEngagees < dateMaxTransmisMinistere) ||
            ((reglement.statut === Statut.EnPreparation || reglement.statut === Statut.MiseAJourEnPreparation) && reglement.dateModification < dateMaxPreparation)) {
            aSupprimer.push(reglement)
        }
    }

    if (aSupprimer.length) {
        return `Avant d’ajouter un nouveau règlement d’emprunt, vous devez d’abord supprimer celui-ci(ceux-ci) : ${aSupprimer.map(r => r.infosLegales.numero).join(", ")}.

Si ce n’est déjà fait, vous devriez d’abord en conserver une copie. Pour ce faire, accéder d’abord à ce(s) règlement(s), puis télécharger une version « pdf » à l’aide du bouton à cet effet.`
    }
}

export function isValidForSave(reglement: Reglement, triedSave: boolean) {
    return (!triedSave || (reglement.infosLegales.numero && reglement.infosLegales.objet)) &&
        validationsReglement.entite.personneRessource.courriel(reglement) &&
        validationsReglement.entite.personneRessource.telephone(reglement) &&
        validationsReglement.entite.courrielTransmission(reglement)
}

function validerInfosLegales(reglement: Reglement) {
    const validations = []

    if (reglement.infosLegales.reglementModifieUnAutre && !reglement.infosLegales.numeroReglementModifie) {
        validations.push("Le règlement en modifie un autre, le numéro de ce règlement est requis")
    }

    if (validations.length) {
        return {
            titre: "Informations légales",
            validations
        } as ValidationGroup
    }
}

function validerApprobationPHV(reglement: Reglement) {
    const validations = []

    if (reglement.approbationPHV.nbJoursRegistre < Helpers.getNbJrsRegistre(reglement)) {
        validations.push(`Le nombre de jours de tenue de registre doit être au moins ${Helpers.getNbJrsRegistre(reglement)} jour(s)`)
    }

    if (validations.length) {
        return {
            titre: "Approbation des PHV",
            validations
        } as ValidationGroup
    }
}

function validerEntite(reglement: Reglement) {
    const validations = []

    if (!reglement.entite.repondant.titreCivilite) {
        validations.push("Le titre de civilité du répondant est requis")
    }

    if (reglement.entite.repondant.titreCivilite === TitreCivilite.Autre && !reglement.entite.repondant.titreCivilitePrecision) {
        validations.push("La précision du titre de civilité du répondant est requise")
    }

    if (!reglement.entite.repondant.prenom) {
        validations.push("Le prénom du répondant est requis")
    }

    if (!reglement.entite.repondant.nom) {
        validations.push("Le nom du répondant est requis")
    }

    if (!reglement.entite.repondant.fonction) {
        validations.push("La fonction du répondant est requis")
    }

    if (!reglement.entite.personneRessource.courriel) {
        validations.push("Le courriel du répondant est requis")
    } else if (!validationsReglement.entite.personneRessource.courriel(reglement)) {
        validations.push(messagesValidation.entite.personneRessource.courriel)
    }

    if (!reglement.entite.personneRessource.nomComplet) {
        validations.push("Le nom complet de la personne ressource est requis")
    }

    if (!reglement.entite.personneRessource.fonction) {
        validations.push("La fonction de la personne ressource est requis")
    }

    if (!reglement.entite.personneRessource.courriel) {
        validations.push("Le courriel de la personne ressource est requis")
    } else if (!validationsReglement.entite.personneRessource.courriel(reglement)) {
        validations.push(messagesValidation.entite.personneRessource.courriel)
    }

    if (!reglement.entite.personneRessource.telephone) {
        validations.push("Le téléphone de la personne ressource est requis")
    } else if (!validationsReglement.entite.personneRessource.telephone(reglement)) {
        validations.push(messagesValidation.entite.personneRessource.telephone)
    }

    if (reglement.entite.courrielTransmission && !validationsReglement.entite.courrielTransmission(reglement)) {
        validations.push(messagesValidation.entite.courrielTransmission)
    }

    if (validations.length) {
        return {
            titre: "Informations sur l'entité",
            validations
        } as ValidationGroup
    }
}

export function validerEvenements(reglement: Reglement) {
    const validations = []

    if (!reglement.evenements.depotProjet) {
        validations.push("La date de dépôt du projet de règlement est requise")
    }
    
    if (!reglement.evenements.avisMotion) {
        validations.push("La date de l'avis de motion est requise")
    }

    if (!reglement.evenements.adoptionProjet) {
        validations.push("La date d'adoption du projet de règlement est requise")
    } else if (reglement.evenements.depotProjet && reglement.evenements.avisMotion) {
        const depotProjet = new Date(`${reglement.evenements.depotProjet} `)
        const avisMotion = new Date(`${reglement.evenements.avisMotion} `)

        const dateMin = depotProjet > avisMotion ? Helpers.getDateMaxOuvrable(reglement.evenements.depotProjet, 2) : Helpers.getDateMaxOuvrable(reglement.evenements.avisMotion, 2)

        if (new Date(`${reglement.evenements.adoptionProjet} `) < dateMin) {
            validations.push(`L'adoption du projet de règlement doit être au plus tôt le deuxième jour ouvrable suivant la dernière des séances durant laquelle a été donné l'avis de motion ou déposé le projet de règlement, soit au plus tôt le ${Helpers.formatDateTexte(dateMin)}`)
        }
    }

    if (reglement.approbationPHV.type === TypeApprobationPHV.Oui) {
        if (!reglement.evenements.publicationAvisApprobationPHV) {
            validations.push("La date de la publication de l'avis pour l'approbation des PHV est requise")
        } else if (reglement.evenements.premiereJourneeRegistre) {
            const dateMax = Helpers.getDateMaxOuvrable(reglement.evenements.premiereJourneeRegistre, 5, false)

            if (new Date(`${reglement.evenements.publicationAvisApprobationPHV} `) > dateMax) {
                validations.push(`La date de la publication de l'avis pour l'approbation des PHV doit être au plus tard le cinquième jour ouvrable qui précède la première journée de tenue du registre, soit au plus tard le ${Helpers.formatDateTexte(dateMax)}`)
            }
        }

        if (!reglement.evenements.premiereJourneeRegistre) {
            validations.push("La date de première journée de tenue de registre est requise")
        } else if (reglement.evenements.adoptionProjet) {
            const dateMax = Helpers.getDateMax(reglement.evenements.adoptionProjet, 46)
            const premiereJourneeRegistre = new Date(`${reglement.evenements.premiereJourneeRegistre} `)
            const adoptionProjet = new Date(`${reglement.evenements.adoptionProjet} `) 

            if (premiereJourneeRegistre < adoptionProjet || premiereJourneeRegistre > dateMax) {
                validations.push(`La tenue du registre doit être effectuée dans la période de 45 jours qui suit l'adoption du projet de règlement, soit entre le ${Helpers.formatDateTexte(adoptionProjet)} et le ${Helpers.formatDateTexte(dateMax)}`)
            }
        }

        if (!reglement.evenements.certificatPublication) {
            validations.push("La date du certificat de publication est requise")
        }
    }

    if (reglement.approbationPHV.avis30Jours && !reglement.evenements.avis30jours) {
        validations.push("La date de l'avis de 30 jours est requise")
    }

    if (reglement.approbationPHV.type === TypeApprobationPHV.Renonciation && !reglement.evenements.avisRenonciation) {
        validations.push("La date de l'avis de renonciation est requise")
    }

    if (validations.length) {
        return {
            titre: "Événements",
            validations
        } as ValidationGroup
    }
}

export function validerDocuments(reglement: Reglement) {
    const validations = []

    if (!Object.keys(reglement.documents).length) {
        validations.push("Veuillez joindre les documents du projet de règlement")
    }

    if (validations.length) {
        return {
            titre: "Documents",
            validations
        } as ValidationGroup
    }
}

export function validerInfosFinancieres(reglement: Reglement) {
    const validations = []



    if (validations.length) {
        return {
            titre: "Informations financières",
            validations
        } as ValidationGroup
    }
}

export function validerRepartitionFiscale(reglement: Reglement) {
    const validations = []

    if (reglement.repartitionFiscale.annuite.reduce((somme, montant) => somme += (montant.annuite || 0), 0) !== reglement.repartitionFiscale.fardeauFiscalEmprunt.reduce((somme, montant) => (somme += montant.montant || 0), 0)) {
        validations.push("Le montant total de l'annuité doit être égal au montant total du fardeau fiscal de l'emprunt")
    }

    if (validations.length) {
        return {
            titre: "Répartition fiscale",
            validations
        } as ValidationGroup
    }
}

export function validateAll(reglement: Reglement) {
    const validations = [] as ValidationGroup[]

    const infosLegales = validerInfosLegales(reglement)
    if (infosLegales) {
        validations.push(infosLegales)
    }

    const phv = validerApprobationPHV(reglement)
    if (phv) {
        validations.push(phv)
    }

    const entite = validerEntite(reglement)
    if (entite) {
        validations.push(entite)
    }

    const evenements = validerEvenements(reglement)
    if (evenements) {
        validations.push(evenements)
    }

    const documents = validerDocuments(reglement)
    if (documents) {
        validations.push(documents)
    }

    const financieres = validerInfosFinancieres(reglement)
    if (financieres) {
        validations.push(financieres)
    }

    const repartitionFiscale = validerRepartitionFiscale(reglement)
    if (repartitionFiscale) {
        validations.push(repartitionFiscale)
    }

    return validations
}

export function validateTelechargement(reglement: Reglement) {
    return reglement.statut === Statut.TransmisMinistere || (reglement.attestation.attestationDisponibiliteFonds && reglement.attestation.attestationDepensesEngagees && reglement.attestation.attestationRenseignementsFournis && (reglement.attestation.personneAttesteMemeRepondant || (reglement.attestation.personne.nomComplet && reglement.attestation.personne.fonction && reglement.attestation.personne.courriel && validationsReglement.attestation.personne.courriel(reglement) && reglement.attestation.personne.telephone)) && reglement.attestation.montantDepensesEngagees > 0)
}