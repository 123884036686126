import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = { class: "button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_grid_vue = _resolveComponent("ag-grid-vue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          class: "btn btn-primary",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onAjouterClick && _ctx.onAjouterClick(...args)))
        }, "Ajouter")
      ])
    ]),
    _createVNode(_component_ag_grid_vue, {
      style: {"width":"100%","height":"calc(100vh - 100px)"},
      class: "ag-theme-alpine",
      columnDefs: _ctx.columnDefs,
      rowData: _ctx.rowData,
      defaultColDef: _ctx.defaultColDef,
      onGridReady: _ctx.onGridReady,
      rowModelTypes: "infinite",
      "grid-options": _ctx.gridOptions
    }, null, 8 /* PROPS */, ["columnDefs", "rowData", "defaultColDef", "onGridReady", "grid-options"])
  ]))
}