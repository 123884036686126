import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "toast-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.params.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["toast text-white bottom-0 end-0 show bg-gradient", { 'bg-danger': _ctx.params.status === _ctx.ToastStatus.Error, 'bg-primary': _ctx.params.status === _ctx.ToastStatus.Success }]),
        role: "alert",
        "aria-live": "assertive",
        "aria-atomic": "true"
      }, [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.params.text), 1 /* TEXT */)
      ], 2 /* CLASS */))
    : _createCommentVNode("v-if", true)
}