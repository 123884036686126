<template>
    <div v-if="params.show" class="toast text-white bottom-0 end-0 show bg-gradient" role="alert" aria-live="assertive"
        aria-atomic="true"
        :class="{ 'bg-danger': params.status === ToastStatus.Error, 'bg-primary': params.status === ToastStatus.Success }">
        <div class="toast-body">
            {{ params.text }}
        </div>
    </div>
</template>

<style>
.toast {
    position: fixed;
    z-index: 5;
    margin: 1em;
}
</style>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import { ToastParams, ToastStatus } from '../../dialog-params'

export default defineComponent({
    props: {
        params: { type: Object as PropType<ToastParams>, required: true }
    },
    data() {
        return {
            ToastStatus
        }
    },
    watch: {
        "params.show"() {
            if (this.params.show) {
                setTimeout(() => {
                    this.params.show = false
                }, 5000)
            }
        }
    }
})
</script>