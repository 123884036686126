export default interface DialogParams {
    show: boolean
    title: string
    innerText: string
    dialogButtons: DialogButtons
    confirm: () => void
}

export enum DialogButtons {
    Ok,
    AnnulerContinuer
}

export interface ToastParams {
    show: boolean
    text: string
    status: ToastStatus
}

export enum ToastStatus {
    Error,
    Success
}
