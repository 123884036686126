<template>
    <Card title="Informations financières" :forced-opened="forcedOpened">
        <div class="row">
            <div class="col-sm-12 col-xl-6">
                <div class="card">
                    <h6 class="card-header">Dépenses</h6>
                    <div class="card-body">
                        <div class="col-sm-12 mt-2">
                            <label for="financieres.depenses.coutsDirects" class="me-2 col-form-label col-sm-8">Coûts
                                directs</label>
                            <div class="form-group mnt d-inline-block">
                                <InputMontant :parent="reglement.financieres.depenses" champ="coutsDirects"
                                    id="financieres.depenses.coutsDirects" :readonly="readonly"
                                    :on-input="calculerTotalDepenses" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-2">
                            <label for="financieres.depenses.honorairesProfessionnels"
                                class="me-2 col-form-label col-sm-8">Honoraires professionnels</label>
                            <div class="form-group mnt d-inline-block">
                                <InputMontant :parent="reglement.financieres.depenses" champ="honorairesProfessionnels"
                                    id="financieres.depenses.honorairesProfessionnels" :readonly="readonly"
                                    :on-input="calculerTotalDepenses" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-2">
                            <label for="financieres.depenses.fraisFinancement" class="me-2 col-form-label col-sm-8">Frais de
                                financement</label>
                            <div class="form-group mnt d-inline-block">
                                <InputMontant :parent="reglement.financieres.depenses" champ="fraisFinancement"
                                    id="financieres.depenses.fraisFinancement" :readonly="readonly"
                                    :on-input="calculerTotalDepenses" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-2">
                            <label for="financieres.depenses.imprevusContingence"
                                class="me-2 col-form-label col-sm-8">Imprévus
                                et
                                contingence</label>
                            <div class="form-group mnt d-inline-block">
                                <InputMontant :parent="reglement.financieres.depenses" champ="imprevusContingence"
                                    id="financieres.depenses.imprevusContingence" :readonly="readonly"
                                    :on-input="calculerTotalDepenses" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-2">
                            <label for="financieres.depenses.taxesNettes" class="me-2 col-form-label col-sm-8">Taxes
                                nettes</label>
                            <div class="form-group mnt d-inline-block">
                                <InputMontant :parent="reglement.financieres.depenses" champ="taxesNettes"
                                    id="financieres.depenses.taxesNettes" :readonly="readonly"
                                    :on-input="calculerTotalDepenses" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-2">
                            <label for="financieres.depenses.fraisAutres" class="me-2 col-form-label col-sm-8">Frais
                                autres</label>
                            <div class="form-group mnt d-inline-block">
                                <InputMontant :parent="reglement.financieres.depenses" champ="fraisAutres"
                                    id="financieres.depenses.fraisAutres" :readonly="readonly"
                                    :on-input="calculerTotalDepenses" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-2">
                            <label for="financieres.depenses.total" class="me-2 col-form-label col-sm-8 fw-bold">Dépenses
                                totales</label>
                            <div class="form-group mnt d-inline-block total">
                                <InputMontant :parent="reglement.financieres.depenses" champ="total"
                                    id="financieres.depenses.total" readonly />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-xl-6">
                <div class="card">
                    <h6 class="card-header">Crédits et détermination de l'emprunt</h6>
                    <div class="card-body">
                        <div class="col-sm-12 mt-2">
                            <label for="financieres.credits.subventionsConfirmees"
                                class="me-2 col-form-label col-sm-8">Subventions
                                confirmées et payées comptant</label>
                            <div class="form-group mnt d-inline-block">
                                <InputMontant :parent="reglement.financieres.credits" champ="subventionsConfirmees"
                                    id="financieres.credits.subventionsConfirmees" :readonly="readonly"
                                    :on-input="calculerTotalCredits" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-2">
                            <label for="financieres.credits.contributionsFonds"
                                class="me-2 col-form-label col-sm-8">Contributions
                                du fonds général, d'autres fonds ou des réserves financières</label>
                            <div class="form-group mnt d-inline-block">
                                <InputMontant :parent="reglement.financieres.credits" champ="contributionsFonds"
                                    id="financieres.credits.contributionsFonds" :readonly="readonly"
                                    :on-input="calculerTotalCredits" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-2">
                            <label for="financieres.credits.soldeDisponible" class="me-2 col-form-label col-sm-8">Solde
                                disponible
                                de règlement d'emprunt fermé</label>
                            <div class="form-group mnt d-inline-block">
                                <InputMontant :parent="reglement.financieres.credits" champ="soldeDisponible"
                                    id="financieres.credits.soldeDisponible" :readonly="readonly"
                                    :on-input="calculerTotalCredits" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-2">
                            <label for="financieres.credits.autresSources" class="me-2 col-form-label col-sm-8">Autres
                                sources</label>
                            <div class="form-group mnt d-inline-block">
                                <InputMontant :parent="reglement.financieres.credits" champ="autresSources"
                                    id="financieres.credits.autresSources" :readonly="readonly"
                                    :on-input="calculerTotalCredits" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-2">
                            <label for="financieres.credits.total" class="me-2 col-form-label col-sm-8 fw-bold">Total des
                                crédits
                                disponibles</label>
                            <div class="form-group mnt d-inline-block total">
                                <InputMontant :parent="reglement.financieres.credits" champ="total"
                                    id="financieres.credits.total" readonly />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="card">
                    <h6 class="card-header">Emprunt prévu au règlement à approuver (Dépenses totales - Crédits disponibles)</h6>
                    <div class="card-body">
                        <div class="col-sm-12 mt-2">
                            <label for="financieres.empruntPrevu" class="me-2 col-form-label col-sm-8 fw-bold">Emprunt prévu au règlement à approuver (Dépenses totales - Crédits disponibles)</label>
                            <div class="form-group mnt d-inline-block total">
                                <InputMontant :parent="reglement.financieres" champ="empruntPrevu"
                                    id="financieres.empruntPrevu" readonly />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-2">
                            <label for="financieres.montantsApresApprobation.subventions" class="me-2 col-form-label col-sm-8">Subventions à recevoir ou payables sur plusieurs années</label>
                            <div class="form-group mnt d-inline-block">
                                <InputMontant :parent="reglement.financieres.montantsApresApprobation" champ="subventions"
                                    id="financieres.montantsApresApprobation.subventions" :readonly="readonly"
                                    :on-input="calculerTotalMontantsApresApprobation" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-2">
                            <label for="financieres.montantsApresApprobation.autresSources" class="me-2 col-form-label col-sm-8">Autres
                                sources</label>
                            <div class="form-group mnt d-inline-block">
                                <InputMontant :parent="reglement.financieres.montantsApresApprobation" champ="autresSources"
                                    id="financieres.montantsApresApprobation.autresSources" :readonly="readonly"
                                    :on-input="calculerTotalMontantsApresApprobation" />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-2">
                            <span class="empty"></span>
                            <div class="form-group mnt d-inline-block total">
                                <InputMontant :parent="reglement.financieres.montantsApresApprobation" champ="total"
                                    id="financieres.montantsApresApprobation" readonly />
                            </div>
                        </div>

                        <div class="col-sm-12 mt-2">
                            <label for="financieres.empruntACharge" class="me-2 col-form-label col-sm-8 fw-bold">Emprunt à la charge de l'organisme municipal</label>
                            <div class="form-group mnt d-inline-block total">
                                <InputMontant :parent="reglement.financieres" champ="empruntACharge"
                                    id="financieres.empruntACharge" readonly />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Card>
</template>
<style lang="scss" scoped>
.card-body>div {
    display: flex;

    >label, .empty {
        flex: 1;
    }
}
</style>
<script lang="ts">
import { PropType, defineComponent } from "vue"
import Reglement from "../../reglement"
import Helpers from "../../helpers"
import Card from "../composants/card.vue"
import InputMontant from "../composants/input-montant.vue"

export default defineComponent({
    props: {
        reglement: { type: Object as PropType<Reglement>, required: true },
        forcedOpened: { type: Boolean, required: true },
        readonly: { type: Boolean, required: true }
    },
    data() {
        return {
            fold: true,
            champsValides: {},
            Helpers
        }
    },
    components: { Card, InputMontant },
    methods: {
        calculerTotalDepenses() {
            this.reglement.financieres.depenses.total = (this.reglement.financieres.depenses.coutsDirects || 0) + (this.reglement.financieres.depenses.honorairesProfessionnels || 0) + (this.reglement.financieres.depenses.fraisFinancement || 0) + (this.reglement.financieres.depenses.imprevusContingence || 0) + (this.reglement.financieres.depenses.taxesNettes || 0) + (this.reglement.financieres.depenses.fraisAutres || 0)
            this.calculerEmpruntPrevu()
        },
        calculerTotalCredits() {
            this.reglement.financieres.credits.total = (this.reglement.financieres.credits.subventionsConfirmees || 0) + (this.reglement.financieres.credits.contributionsFonds || 0) + (this.reglement.financieres.credits.soldeDisponible || 0) + (this.reglement.financieres.credits.autresSources || 0)
            this.calculerEmpruntPrevu()
        },
        calculerEmpruntPrevu() {
            this.reglement.financieres.empruntPrevu = (this.reglement.financieres.depenses.total || 0) - (this.reglement.financieres.credits.total || 0)
            this.calculerEmpruntACharge()
        },
        calculerTotalMontantsApresApprobation() {
            this.reglement.financieres.montantsApresApprobation.total = (this.reglement.financieres.montantsApresApprobation.subventions || 0) + (this.reglement.financieres.montantsApresApprobation.autresSources || 0)
            this.calculerEmpruntACharge()
        },
        calculerEmpruntACharge() {
            this.reglement.financieres.empruntACharge = (this.reglement.financieres.empruntPrevu || 0) - (this.reglement.financieres.montantsApresApprobation.total || 0)
        }
    }
})
</script>