<template>
    <div>
        <button class="btn" @click="supprime">
            <i class="fa fa-trash"></i>
        </button>
        <button class="btn" @click="edit">
            <i class="fa fa-angles-right"></i>
        </button>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        params: { type: Object, required: true }
    },
    methods: {
        supprime() {
            this.params.supprime(this.params.data)
        },
        edit() {
            this.params.edit(this.params.data)
        }
    }
})
</script>