<template>
    <Card title="Attestation" :forced-opened="forcedOpened" opened>
        <div class="col-sm-12">
            <div class="card mt-3">
                <h6 class="card-header">Personne qui atteste</h6>
                <div class="card-body">
                    <div>
                        <input type="checkbox" class="form-check-input"
                            :disabled="reglement.statut === Statut.TransmisMinistere"
                            id="attestation.personneAttesteMemeRepondant"
                            v-model="reglement.attestation.personneAttesteMemeRepondant"
                            @change="onPersonneMemeQueRepondantChange" />
                        <label class="form-check-label" for="attestation.personneAttesteMemeRepondant">
                            La personne qui atteste est la même que le répondant
                        </label>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12" v-if="!reglement.attestation.personneAttesteMemeRepondant">
                        <label for="attestation.personne.nomComplet">Nom complet</label>
                        <input type="text" class="form-control" id="attestation.personne.nomComplet"
                            v-model="reglement.attestation.personne.nomComplet"
                            :readonly="reglement.statut === Statut.TransmisMinistere" />
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12" v-if="!reglement.attestation.personneAttesteMemeRepondant">
                        <label for="attestation.personne.fonction">Fonction</label>
                        <input type="text" class="form-control" id="attestation.personne.fonction"
                            v-model="reglement.attestation.personne.fonction"
                            :readonly="reglement.statut === Statut.TransmisMinistere" />
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12" v-if="!reglement.attestation.personneAttesteMemeRepondant">
                        <label for="attestation.personne.courriel">Courriel</label>
                        <input type="text" class="form-control" id="attestation.personne.courriel"
                            v-model="reglement.attestation.personne.courriel"
                            :readonly="reglement.statut === Statut.TransmisMinistere" @change="onCourrielChange" />
                        <p class="text-danger" v-if="!champsValides.courriel">
                            {{ messagesValidation.courriel }}
                        </p>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12" v-if="!reglement.attestation.personneAttesteMemeRepondant">
                        <label for="attestation.personne.telephone">Téléphone</label>
                        <input type="text" class="form-control" id="attestation.personne.telephone"
                            v-model="reglement.attestation.personne.telephone"
                            placeholder="999-999-9999#999999"
                            :readonly="reglement.statut === Statut.TransmisMinistere"
                            @change="onTelephoneChange" />
                        <p class="text-danger" v-if="!champsValides.telephone">
                            {{ messagesValidation.telephone }}
                        </p>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12" v-if="!reglement.attestation.personneAttesteMemeRepondant">
                        <label for="attestation.personne.commentaire">Commentaire</label>
                        <input type="text" class="form-control" id="attestation.personne.commentaire"
                            v-model="reglement.attestation.personne.commentaire"
                            :readonly="reglement.statut === Statut.TransmisMinistere" />
                    </div>
                </div>
            </div>

            <div class="card mt-3">
                <h6 class="card-header">Disponibilité du fonds général, d'autres fonds et de réserves financières</h6>
                <div class="card-body atteste-flex">
                    <p>L'organisme municipal dispose actuellement des crédits pour un montant de {{
                        reglement.financieres.credits.total }} $ provenant du fonds général, de tout autre fonds et de
                        réserves
                        financières à affecter au règlement.</p>
                    <div>
                        <input type="checkbox" class="form-check-input"
                            :disabled="reglement.statut === Statut.TransmisMinistere"
                            id="attestation.attestationDisponibiliteFonds"
                            v-model="reglement.attestation.attestationDisponibiliteFonds" />
                        <label class="form-check-label" for="attestation.attestationDisponibiliteFonds">
                            J'atteste
                        </label>
                    </div>
                </div>
            </div>
            <div class="card mt-3">
                <h6 class="card-header">Dépenses engagées</h6>
                <div class="card-body atteste-flex">
                    <div>
                        <p>En vertu des articles 544.1 de la Loi sur les cités et villes ou 1063.1 du
                            Code municipal du Québec,
                            le renflouement du fonds général pour les dépenses engagées avant l'entrée en vigeur du
                            règlement ne
                            doit pas excéder 5 % des dépenses prévues au règlement. Le taux est de 10 % si le règlement ne
                            requiert pas l'approbation des personnes habiles à voter.</p>

                        <div class="col-sm-12 mt-2">
                            <label for="attestation.montantDepensesEngagees" class="me-2 col-form-label">
                                En date du {{ Helpers.formatDateTexte(new Date()) }},
                                la dépense engagée au règlement {{ reglement.infosLegales.numero }} est de
                            </label>
                            <div class="form-group col-sm mnt">
                                <InputMontant :parent="reglement.attestation" champ="montantDepensesEngagees"
                                    id="attestation.montantDepensesEngagees"
                                    :readonly="reglement.statut === Statut.TransmisMinistere" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <input type="checkbox" class="form-check-input"
                            :disabled="reglement.statut === Statut.TransmisMinistere || !reglement.attestation.montantDepensesEngagees || reglement.attestation.montantDepensesEngagees < 0"
                            id="attestation.attestationDepensesEngagees"
                            v-model="reglement.attestation.attestationDepensesEngagees" />
                        <label class="form-check-label" for="attestation.attestationDepensesEngagees">
                            J'atteste
                        </label>
                    </div>
                </div>
            </div>
            <div class="card mt-3">
                <h6 class="card-header">Attestation des renseignements fournis</h6>
                <div class="card-body atteste-flex">
                    <p>Je, {{ reglement.attestation.personneAttesteMemeRepondant ? `${reglement.entite.repondant.prenom}
                                            ${reglement.entite.repondant.nom}` : reglement.attestation.personne.nomComplet }}, {{
                            reglement.attestation.personneAttesteMemeRepondant ? reglement.entite.repondant.fonction :
                            reglement.attestation.personne.fonction }}, certifie que les renseignements fournis dans cette
                        demande sont
                        exacts.</p>
                    <div>
                        <input type="checkbox" class="form-check-input"
                            :disabled="reglement.statut === Statut.TransmisMinistere"
                            id="attestation.attestationRenseignementsFournis"
                            v-model="reglement.attestation.attestationRenseignementsFournis" />
                        <label class="form-check-label" for="attestation.attestationRenseignementsFournis">
                            J'atteste
                        </label>
                    </div>
                </div>
            </div>
            <div class="text-end">
                <button v-if="reglement.statut !== Statut.TransmisMinistere" class="btn btn-primary"
                    @click="corrigerInfos">Corriger les informations</button>
                <button :disabled="!validateTelechargement(reglement)" class="btn btn-primary"
                    @click="envoyerMinistere">Télécharger pour envoyer au Ministère</button>
            </div>
        </div>
    </Card>
</template>
<style lang="scss" scoped>
.atteste-flex {
    display: flex;
    align-items: flex-end;

    >:first-child {
        flex: 1;
        margin-bottom: 0;
    }
}

.mnt {
    vertical-align: -webkit-baseline-middle;
}

.btn {
    margin-left: 1em;
}
</style>
<script lang="ts">
import { PropType, defineComponent } from "vue"
import Reglement, { Statut } from "../../reglement"
import Card from "../composants/card.vue"
import * as messagesValidation from "../../messages/validation.json"
import { validateTelechargement, validationsReglement } from "../../validate-reglement"
import InputMontant from "../composants/input-montant.vue"
import Helpers from "../../helpers"

export default defineComponent({
    props: {
        reglement: { type: Object as PropType<Reglement>, required: true },
        forcedOpened: { type: Boolean, required: true },
        save: { type: Function, required: true }
    },
    data() {
        return {
            fold: false,
            champsValides: {
                courriel: true,
                telephone: true
            },
            Helpers,
            Statut,
            validateTelechargement,
            messagesValidation: messagesValidation.attestation
        }
    },
    components: { Card, InputMontant },
    methods: {
        envoyerMinistere() {
            this.reglement.statut = Statut.TransmisMinistere
            this.save().then(() => {
                window.open(`${window.location.href}api/export/${this.reglement._id}`, "_blank")
            })
        },
        corrigerInfos() {
            this.reglement.statut = Statut.EnPreparation
            this.reglement.attestation.attestationDisponibiliteFonds = false
            this.reglement.attestation.attestationDepensesEngagees = false
            this.reglement.attestation.attestationRenseignementsFournis = false
            this.save()
        },
        onPersonneMemeQueRepondantChange() {
            if (this.reglement.attestation.personneAttesteMemeRepondant) {
                this.reglement.attestation.nomComplet = undefined
                this.reglement.attestation.fonction = undefined
                this.reglement.attestation.courriel = undefined
                this.reglement.attestation.telephone = undefined
            }
        },
        onCourrielChange() {
            this.champsValides.courriel = validationsReglement.attestation.personne.courriel(this.reglement)
        },
        onTelephoneChange() {
            this.champsValides.telephone = validationsReglement.attestation.personne.telephone(this.reglement)
        }
    }
})
</script>