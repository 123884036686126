<template>
    <Card title="Documents" :forced-opened="forcedOpened" opened>
        <table class="table">
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Documents joints</th>
                    <th v-if="!readonly" class="nouveau-document">Nouveau</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="typeDocument in TypesDocument" :key="typeDocument">
                    <td>{{ TypeDocumentString[typeDocument] }}</td>
                    <td>
                        <span v-if="reglement.documents[typeDocument]" v-for="doc in reglement.documents[typeDocument]"
                            class="file"
                            :class="{ 'file-supprime': changementsDocuments.supprime.some(sup => sup.id === doc.id) }">
                            <a :href="`/api/${reglement._id}/fichier/${doc.id}`" :download="doc.nom">
                                <i class="fa fa-file"></i> {{ doc.nom }}
                            </a>
                            <button v-if="!readonly && !changementsDocuments.supprime.some(sup => sup.id === doc.id)" class="btn" @click="deleteDocument(doc, typeDocument)">
                                <i class="fa fa-trash"></i>
                            </button>
                            <button v-if="!readonly && changementsDocuments.supprime.some(sup => sup.id === doc.id)" class="btn" @click="revertDeleteDocument(doc)">
                                <i class="fa fa-rotate-left"></i>
                            </button>
                        </span>
                    </td>
                    <td v-if="!readonly" class="nouveau-document">
                        <input class="form-control" type="file" multiple accept=".pdf"
                        :key="reglement.documents[typeDocument] ? reglement.documents[typeDocument].map(d => d.id).join(',') : ''"
                            @change="onFileChange($event as any, typeDocument)" />
                    </td>
                </tr>
            </tbody>
        </table>
    </Card>
</template>
<style lang="scss" scoped>
span.file {
    display: block;
}

span.file-supprime>a {
    color: red !important;
    text-decoration: line-through;
}

td {
    min-width: 250px;
}
</style>
<script lang="ts">
import { PropType, defineComponent } from "vue"
import Reglement, { DocumentRE, TypesDocument, TypeDocumentString } from "../../reglement"
import Card from "../composants/card.vue"
import DialogParams, { DialogButtons } from "../../dialog-params"

export default defineComponent({
    props: {
        reglement: { type: Object as PropType<Reglement>, required: true },
        changementsDocuments: { type: Object, required: true },
        showDialog: { type: Function, required: true },
        forcedOpened: { type: Boolean, required: true },
        readonly: { type: Boolean, required: true }
    },
    data() {
        return {
            TypesDocument,
            TypeDocumentString,
            champsValides: {}
        }
    },
    methods: {
        onFileChange(event: InputEvent, type: number) {
            let files = Array.from((event.target as HTMLInputElement).files)
            if (files.some(f => f.type !== "application/pdf")) {
                (event.target as HTMLInputElement).value = null
                return this.showDialog({
                    title: "Nouveau document",
                    innerText: "Les documents doivent être au format PDF",
                    dialogButtons: DialogButtons.Ok
                } as DialogParams)
            }

            this.changementsDocuments.nouveaux = this.changementsDocuments.nouveaux.filter(ndoc => ndoc.type !== type)

            for (const file of files) {
                this.changementsDocuments.nouveaux.push({
                    type,
                    file
                })
            }
        },
        deleteDocument(doc: DocumentRE, type: number) {
            this.changementsDocuments.supprime.push({id:doc.id, type})
        },
        revertDeleteDocument(doc: DocumentRE) {
            this.changementsDocuments.supprime = this.changementsDocuments.supprime.filter(sup => sup.id !== doc.id)
        }
    },
    components: { Card }
})
</script>