<template>
    <div class="card">
        <h5 class="card-header" :class="collapsed && !forcedOpened ? 'collapsed' : ''" @click="collapsed = !collapsed">
            <i class="fa float-end" :class="collapsed ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
            {{ title }}
        </h5>
        <div v-if="!collapsed || forcedOpened" class="card-body" :class="forcedOpened ? 'card-body-forced-open' : ''">
            <slot></slot>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
    props: {
        title: { type: String, required: true },
        forcedOpened: { type: Boolean, required: true },
        opened: { type: Boolean, default: false }
    },
    data() {
        return {
            collapsed: !this.opened
        }
    }
})
</script>