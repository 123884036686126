export default interface Reglement {
    _id?: string
    statut: Statut
    dateModification: Date
    municipalite: string
    infosLegales: {
        numero: string
        objet: string
        subventionGouvernementale: boolean
        reglementModifieUnAutre: boolean
        numeroReglementModifie: string
    }
    entite: {
        codeGeographique?: string//TODO AP authentification
        repondant: {
            titreCivilite: TitreCivilite
            titreCivilitePrecision: string
            prenom: string
            nom: string
            fonction: string,
            courriel: string
        }
        personneRessource: {
            memeQueRepondant: boolean
            nomComplet: string
            fonction: string
            courriel: string
            telephone: string
            commentaire: string
        }
        courrielTransmission: string
    }
    documents: { [key: string]: DocumentRE[] },
    approbationPHV: {
        type: TypeApprobationPHV
        motifExemption: string
        avis30Jours: boolean
        nbPHVNécessaires: number
        nbSignatures: number
        nbJoursRegistre: number
    }
    evenements: {
        avisMotion: string
        depotProjet: string
        adoptionProjet: string
        publicationAvisApprobationPHV: string
        premiereJourneeRegistre: string
        certificatPublication: string
        avisRenonciation: string
        avis30jours: string
    }
    financieres: {
        depenses: {
            coutsDirects: number
            honorairesProfessionnels: number
            fraisFinancement: number
            imprevusContingence: number
            taxesNettes: number
            fraisAutres: number
            total: number
        }
        credits: {
            subventionsConfirmees: number
            contributionsFonds: number
            soldeDisponible: number
            autresSources: number
            total: number
        }
        empruntPrevu: number
        montantsApresApprobation: {
            subventions: number
            autresSources: number
            total: number
        }
        empruntACharge: number
    }
    repartitionFiscale: {
        annuite: Annuite[]
        fardeauFiscalEmprunt: FardeauFiscalEmprunt[]
        fardeauFiscalActuel: {
            taxeGenerale: {
                taux: number
                evaluation: number
                total: number
            },
            tarification: {
                eau: number
                egouts: number
                ordures: number
                total: number
            }
            autresTaxes: {
                precision: string
                montant: number
            }
            total: number
        }
        fardeauFiscalTotal: number
        variationPourcentage: number
    }
    attestation: {
        personneAttesteMemeRepondant: boolean
        personne: {
            nomComplet: string
            fonction: string
            courriel: string
            telephone: string
            commentaire: string
        }
        attestationDisponibiliteFonds: boolean
        attestationDepensesEngagees: boolean
        montantDepensesEngagees: number
        dateDepensesEngagees: string
        attestationRenseignementsFournis: boolean
    }
}

export const TypesDocument = Object.freeze([1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14])

export const TypeDocumentString = {
    1: "Lettre de présentation ou coordonnées de la personne présentant la demande",
    2: "Avis de motion",
    3: "Extrait du procès-verbal ou résolution pour le dépôt du projet de règlement",
    4: "Projet de règlement déposé incluant ses annexes",
    5: "Résolution d’adoption du règlement",
    6: "Copie certifiée conforme du règlement adopté (et ses annexes incluant une description détaillée)",
    7: "Avis public annonçant la procédure d'enregistrement des personnes habiles à voter",
    8: "Certificat de publication de l'avis public",
    10: "Certificat relatif au déroulement de la procédure d’enregistrement des PHV",
    11: "Copie du document confirmant le versement d'une subvention",
    12: "Fiche de règlement d'emprunt remplie et signée",
    13: "Autres documents",
    14: "Copie du document confirmant le versement d'une subvention"
}

export enum Statut {
    EnPreparation = "En préparation",
    Annule = "Annulé",
    RefuseParScrutin = "Refusé par scrutin",
    Termine = "Terminé",
    TransmisMinistere = "Transmis au Ministère",
    MiseAJourEnPreparation = "Mise à jour en préparation"
}

export enum TitreCivilite {
    Monsieur = "Monsieur",
    Madame = "Madame",
    Autre = "Autre"
}

export enum TypeApprobationPHV {
    Oui = "Oui",
    Exemption = "Exemption",
    Renonciation = "Renonciation",
}

export enum MotifExemption {
    Autre = "Autre"
}

export enum TypeContribuable {
    Ensemble = "Ensemble",
    Secteur = "Secteur",
    Riverain = "Riverain"
}

export enum BaseImposition {
    Evaluation = "Selon l'évaluation",
    Superficie = "Selon la superficie",
    EtendueFront = "Selon l'étendue ou front",
    Tarification = "Selon une tarification"
}

export interface DocumentRE {
    id: string
    nom: string
}

export interface Annuite {
    montant: number
    taux: number
    terme: number
    annuite: number
}

export interface FardeauFiscalEmprunt {
    baseImposition: BaseImposition
    typeContribuable: TypeContribuable
    montant: number
    assietteTotaleImposable: number
    uniteMoyenneImposable: number
    chargeFiscale: number
}

export const BaseImpositionUnitePrecision = {
    [BaseImposition.Evaluation]: "en dollars",
    [BaseImposition.Superficie]: "en mètres carrés",
    [BaseImposition.EtendueFront]: "en mètres",
    [BaseImposition.Tarification]: "par unité, compteur, etc."
}

export interface ReglementGrid {
    _id: string
    numero: string
    objet: string
    statut: Statut
    repondant: string
    dateModification: string
}
