<template>
    <Card title="Informations légales" :forced-opened="forcedOpened" opened>
        <div class="form-group col-sm-2">
            <label for="numero">Numéro</label>
            <input type="text" class="form-control" id="numero" v-model="reglement.infosLegales.numero" maxlength="17"
                :class="triedSave && !reglement.infosLegales.numero ? 'is-invalid' : ''" :readonly="readonly" />
            <p class="text-danger" v-if="triedSave && !reglement.infosLegales.numero">{{ messagesValidation.numero }}
            </p>
        </div>

        <div class="form-group col-sm-3">
            <label for="statut">Statut</label>
            <span id="statut" class="form-control disabled">{{ reglement.statut }}</span>
        </div>

        <div class="form-group col-sm-4">
            <span class="d-inline-block">
                <input type="checkbox" class="form-check-input" :disabled="readonly"
                    id="infosLegales.reglementModifieUnAutre" v-model="reglement.infosLegales.reglementModifieUnAutre"
                    @change="onReglementModifieUnAutreChange" />
                <label class="form-check-label" for="infosLegales.reglementModifieUnAutre">
                    Ce règlement en modifie un autre
                </label>
            </span>
            <span class="d-inline-block">
                <input type="checkbox" class="form-check-input" :disabled="readonly"
                    id="infosLegales.subventionGouvernementale" v-model="reglement.infosLegales.subventionGouvernementale"
                    @change="onReglementModifieUnAutreChange" />
                <label class="form-check-label" for="infosLegales.subventionGouvernementale">
                    Ce règlement fait l'objet d'une subvention gouvernementale
                </label>
            </span>
        </div>

        <div class="form-group col-sm-3" v-if="reglement.infosLegales.reglementModifieUnAutre">
            <label for="numeroReglementModifie">Numéro du règlement modifié</label>
            <input type="text" class="form-control" id="numero" v-model="reglement.infosLegales.numeroReglementModifie"
                maxlength="17" />
        </div>

        <div class="form-group col-sm-12">
            <label for="objet">Objet</label>
            <textarea type="text" class="form-control" id="objet" v-model="reglement.infosLegales.objet" rows="3"
                :class="triedSave && !reglement.infosLegales.objet ? 'is-invalid' : ''" :readonly="readonly"></textarea>
            <p class="textarea-print">{{ reglement.infosLegales.objet }}</p>
            <p class="text-danger" v-if="triedSave && !reglement.infosLegales.objet">{{ messagesValidation.objet }}</p>
        </div>

        <div class="card mt-3">
            <h6 class="card-header">Approbation des PHV</h6>
            <div class="card-body">
                <div class="form-group col-sm-4 label-nb-phv">
                    <label for="approbationPHV.type" class="form-label-base">L'approbation des PHV est-elle requise ? Sinon,
                        veuillez indiquer s'il sagit d'une renonciation ou d'une exemption.</label>
                    <select class="form-select" id="approbationPHV.type" @change="onTypeApprobationPHVChange"
                        v-model="reglement.approbationPHV.type" :disabled="readonly">
                        <option v-for="typeAppro in TypeApprobationPHV" :value="typeAppro">{{ typeAppro }}
                        </option>
                    </select>
                </div>
                <div v-if="reglement.approbationPHV.type === TypeApprobationPHV.Exemption" class="form-group col-sm-12">
                    <label for="approbationPHV.motifExemption">
                        Motif d'exemption
                    </label>
                    <textarea type="text" class="form-control" id="approbationPHV.motifExemption"
                        v-model="reglement.approbationPHV.motifExemption" :readonly="readonly"></textarea>
                    <p class="textarea-print">{{ reglement.approbationPHV.motifExemption }}</p>
                </div>
                <div class="col-sm-12 mt-2" v-if="reglement.approbationPHV.type === TypeApprobationPHV.Oui">
                    <label for="approbationPHV.nbPHVNécessaires" class="me-2 col-form-label col-sm-4 label-nb-phv">
                        Nombre de personnes habiles à voter nécessaires à la tenue d'un scrutin
                    </label>
                    <div class="form-group col-sm">
                        <input type="number" class="form-control input-print-keep-margin" :readonly="readonly" min="1"
                            step="1" @keypress="Helpers.allowOnlyNumbers"
                            @input="reglement.approbationPHV.nbPHVNécessaires = Math.floor(reglement.approbationPHV.nbPHVNécessaires)"
                            id="approbationPHV.nbPHVNécessaires" v-model="reglement.approbationPHV.nbPHVNécessaires" />
                    </div>
                    <div v-if="Helpers.getNbJrsRegistre(reglement)" class="col-sm-4 d-inline-block col-form-label aide-phv">
                        Le registre de signatures doit être tenu
                        pendant {{ Helpers.getNbJrsRegistre(reglement) }} jour(s).
                    </div>
                </div>
                <div class="col-sm-12 mt-2" v-if="reglement.approbationPHV.type === TypeApprobationPHV.Oui">
                    <label for="approbationPHV.nbSignatures" class="me-2 col-form-label col-sm-4 label-nb-phv">
                        Nombre de signatures recueillies lors de la tenue de registre
                    </label>
                    <div class="form-group col-sm">
                        <input type="number" class="form-control input-print-keep-margin" :readonly="readonly" min="1"
                            step="1" @keypress="Helpers.allowOnlyNumbers"
                            @input="reglement.approbationPHV.nbSignatures = Math.floor(reglement.approbationPHV.nbSignatures)"
                            id="approbationPHV.nbSignatures" v-model="reglement.approbationPHV.nbSignatures" />
                    </div>
                    <div v-if="getNbSignaturesRequises(reglement)" class="col-sm-4 d-inline-block col-form-label aide-phv">
                        {{ getNbSignaturesRequises(reglement) }} signatures sont requises pour la tenue d'un scrutin référendaire.
                    </div>
                </div>
                <div class="col-sm-12 mt-2" v-if="reglement.approbationPHV.type === TypeApprobationPHV.Oui">
                    <label for="approbationPHV.nbJoursRegistre" class="me-2 col-form-label col-sm-4 label-nb-phv">
                        Nombre de jours de tenue de registre
                    </label>
                    <div class="form-group col-sm">
                        <input type="number" class="form-control input-print-keep-margin" :readonly="readonly" min="1"
                            step="1" @keypress="Helpers.allowOnlyNumbers"
                            @input="reglement.approbationPHV.nbJoursRegistre = Math.floor(reglement.approbationPHV.nbJoursRegistre)"
                            id="approbationPHV.nbJoursRegistre" v-model="reglement.approbationPHV.nbJoursRegistre" />
                    </div>
                </div>
                <div class="mt-4">
                    <input type="checkbox" class="form-check-input" :disabled="readonly" id="approbationPHV.avis30Jours"
                        v-model="reglement.approbationPHV.avis30Jours" @change="onReglementModifieUnAutreChange" />
                    <label class="form-check-label" for="approbationPHV.avis30Jours">
                        Ce règlement a fait l'objet d'un avis de 30 jours
                    </label>
                </div>
            </div>
        </div>

        <p class="fst-italic">
            Saisissez la date de chaque événement, au fur et à mesure ou elles sont connues.
        </p>
        <table class="table">
            <thead>
                <tr>
                    <th>Événement</th>
                    <th>Date</th>
                    <th>Directives</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Dépot du projet de règlement</td>
                    <td><input type="date" class="form-control" v-model="reglement.evenements.depotProjet"
                            :readonly="readonly" />
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>Avis de motion</td>
                    <td><input type="date" class="form-control" v-model="reglement.evenements.avisMotion"
                            :readonly="readonly" />
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>Adoption du projet de règlement</td>
                    <td><input type="date" class="form-control" v-model="reglement.evenements.adoptionProjet"
                            :readonly="readonly" />
                    </td>
                    <td>
                        Au plus tôt le deuxième jour ouvrable suivant la dernière des séances durant laquelle a été
                        donné l'avis de motion ou déposé le projet de règlement.
                    </td>
                </tr>
                <tr v-if="reglement.approbationPHV.type === TypeApprobationPHV.Oui">
                    <td>Publication d'un avis pour l'approbation des PHV</td>
                    <td><input type="date" class="form-control" :readonly="readonly"
                            v-model="reglement.evenements.publicationAvisApprobationPHV" />
                    </td>
                    <td>
                        Au plus tard le cinquième jour ouvrable qui précède la première journée de tenue du registre.
                    </td>
                </tr>
                <tr v-if="reglement.approbationPHV.type === TypeApprobationPHV.Oui">
                    <td>Première journée de tenue de registre</td>
                    <td><input type="date" class="form-control" :readonly="readonly"
                            v-model="reglement.evenements.premiereJourneeRegistre" />
                    </td>
                    <td>
                        La tenue du registre doit être effectuée dans la période de 45 jours qui suit
                        l'adoption du projet de règlement.
                    </td>
                </tr>
                <tr v-if="reglement.approbationPHV.type === TypeApprobationPHV.Oui">
                    <td>Certificat de publication</td>
                    <td><input type="date" class="form-control" v-model="reglement.evenements.certificatPublication"
                            :readonly="readonly" />
                    </td>
                    <td></td>
                </tr>
                <tr v-if="reglement.approbationPHV.type === TypeApprobationPHV.Renonciation">
                    <td>Avis de renonciation</td>
                    <td><input type="date" class="form-control" v-model="reglement.evenements.avisRenonciation"
                            :readonly="readonly" />
                    </td>
                    <td></td>
                </tr>
                <tr v-if="reglement.approbationPHV.avis30Jours">
                    <td>Avis public de 30 jours</td>
                    <td><input type="date" class="form-control" v-model="reglement.evenements.avis30jours"
                            :readonly="readonly" />
                    </td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </Card>
</template>
<script lang="ts">
import { PropType, defineComponent } from 'vue'
import Reglement, { MotifExemption, Statut, TypeApprobationPHV } from '../../reglement'
import * as messagesValidation from "../../messages/validation.json"
import Helpers from "../../helpers"
import Card from '../composants/card.vue'

export default defineComponent({
    props: {
        reglement: { type: Object as PropType<Reglement>, required: true },
        forcedOpened: { type: Boolean, required: true },
        triedSave: { type: Boolean, required: true },
        readonly: { type: Boolean, required: true }
    },
    data() {
        return {
            Statut,
            TypeApprobationPHV,
            MotifExemption,
            fold: false,
            messagesValidation: messagesValidation.infosLegales,
            getNbSignaturesRequises: Helpers.getNbSignaturesRequises,
            Helpers
        }
    },
    methods: {
        onReglementModifieUnAutreChange() {
            if (!this.reglement.infosLegales.reglementModifieUnAutre) {
                this.reglement.infosLegales.numeroReglementModifie = undefined
            }
        },
        onTypeApprobationPHVChange() {
            if (this.reglement.approbationPHV.type !== TypeApprobationPHV.Renonciation) {
                this.reglement.evenements.renonciation = undefined
            }
            if (this.reglement.approbationPHV.type !== TypeApprobationPHV.Oui) {
                this.reglement.evenements.publicationAvisApprobationPHV = undefined
                this.reglement.evenements.premiereJourneeRegistre = undefined
                this.reglement.evenements.certificatPublication = undefined
            }
            if (this.reglement.approbationPHV.type !== TypeApprobationPHV.Exemption) {
                this.reglement.approbationPHV.motifExemption = undefined
            }
        }
    },
    components: { Card }
})
</script>