<template>
    <Card title="Informations sur l'entité" :forced-opened="forcedOpened" opened>
        <!-- <div class="form-group col-sm-4">
            <label for="entite.codeGeographique">Code géographique</label>
            <input type="text" class="form-control" id="entite.codeGeographique"
                v-model="reglement.entite.codeGeographique"
                :class="triedSave && !reglement.entite.codeGeographique ? 'is-invalid' : ''" />
            <p class="text-danger" v-if="triedSave && !reglement.infosLegales.numero">Le numéro est requis pour sauvegarder
            </p>
        </div> -->
        <div class="row">
            <div class="col-sm-6">
                <div class="card">
                    <h6 class="card-header">Répondant</h6>
                    <div class="card-body">
                        <div class="form-group col-sm-6">
                            <label for="entite.repondant.titreCivilite">Titre de civilité</label>
                            <select class="form-select" id="entite.repondant.titreCivilite" :disabled="readonly"
                                v-model="reglement.entite.repondant.titreCivilite">
                                <option v-for="titre in TitreCivilite" :value="titre">{{ titre }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-sm-6"
                            v-if="reglement.entite.repondant.titreCivilite === TitreCivilite.Autre">
                            <label for="entite.repondant.titreCivilitePrecision">Précisez</label>
                            <input type="text" class="form-control" id="entite.repondant.titreCivilitePrecision"
                                :readonly="readonly" v-model="reglement.entite.repondant.titreCivilitePrecision" />
                        </div>
                        <div class="form-group col-sm-12">
                            <label for="entite.repondant.prenom">Prénom</label>
                            <input type="text" class="form-control" id="entite.repondant.prenom" :readonly="readonly"
                                v-model="reglement.entite.repondant.prenom" />
                        </div>
                        <div class="form-group col-sm-12">
                            <label for="entite.repondant.nom">Nom</label>
                            <input type="text" class="form-control" id="entite.repondant.nom" :readonly="readonly"
                                v-model="reglement.entite.repondant.nom" />
                        </div>
                        <div class="form-group col-sm-12">
                            <label for="entite.repondant.fonction">Fonction</label>
                            <input type="text" class="form-control" id="entite.repondant.fonction" :readonly="readonly"
                                v-model="reglement.entite.repondant.fonction" />
                        </div>

                        <div class="form-group col-sm-12">
                            <label for="entite.repondant.courriel">Courriel</label>
                            <input type="text" class="form-control" id="entite.repondant.courriel" :readonly="readonly"
                                v-model="reglement.entite.repondant.courriel" @change="onREpondantCourrielChange"
                                :class="!champsValides.personneRessource.courriel ? 'is-invalid' : ''" />
                            <p class="text-danger" v-if="!champsValides.repondant.courriel">
                                {{ messagesValidation.repondant.courriel }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="card">
                    <h6 class="card-header">Personne ressource</h6>
                    <div class="card-body">
                        <div class="form-group col-sm-12">
                            <input type="checkbox" class="form-check-input" :disabled="readonly"
                                id="entite.personneRessource.memeQueRepondant"
                                v-model="reglement.entite.personneRessource.memeQueRepondant"
                                @change="onPersonneRessourceMemeQueRepondantChange" />
                            <label class="form-check-label" for="entite.personneRessource.memeQueRepondant">
                                La personne ressource est la même que le répondant
                            </label>
                        </div>
                        <div v-if="!reglement.entite.personneRessource.memeQueRepondant" class="form-group col-sm-12">
                            <label for="entite.personneRessource.nomComplet">Nom complet</label>
                            <input type="text" class="form-control" id="entite.personneRessource.nomComplet"
                                v-model="reglement.entite.personneRessource.nomComplet" :readonly="readonly" />
                        </div>
                        <div v-if="!reglement.entite.personneRessource.memeQueRepondant" class="form-group col-sm-12">
                            <label for="entite.personneRessource.fonction">Fonction</label>
                            <input type="text" class="form-control" id="entite.personneRessource.fonction"
                                v-model="reglement.entite.personneRessource.fonction" :readonly="readonly" />
                        </div>
                        <div v-if="!reglement.entite.personneRessource.memeQueRepondant" class="form-group col-sm-12">
                            <label for="entite.personneRessource.courriel">Courriel</label>
                            <input type="email" class="form-control" id="entite.personneRessource.courriel"
                                v-model="reglement.entite.personneRessource.courriel"
                                @change="onPersonneRessourceCourrielChange" :readonly="readonly"
                                :class="!champsValides.personneRessource.courriel ? 'is-invalid' : ''" />
                            <p class="text-danger" v-if="!champsValides.personneRessource.courriel">
                                {{ messagesValidation.personneRessource.courriel }}
                            </p>
                        </div>
                        <div v-if="!reglement.entite.personneRessource.memeQueRepondant" class="form-group col-sm-12">
                            <label for="entite.personneRessource.telephone">Téléphone
                            </label>
                            <input type="text" class="form-control" id="entite.personneRessource.telephone"
                                v-model="reglement.entite.personneRessource.telephone"
                                @change="onPersonneRessourceTelephoneChange"
                                :placeholder="forcedOpened ? '' : '999-999-9999#999999'" :readonly="readonly"
                                :class="!champsValides.personneRessource.telephone ? 'is-invalid' : ''" />
                            <p class="text-danger" v-if="!champsValides.personneRessource.telephone">
                                {{ messagesValidation.personneRessource.telephone }}
                                <!-- TODO AP Format téléphone avec poste -->
                            </p>
                        </div>
                        <div v-if="!reglement.entite.personneRessource.memeQueRepondant" class="form-group col-sm-12">
                            <label for="entite.personneRessource.commentaire">Commentaire</label>
                            <input type="text" class="form-control" id="entite.personneRessource.commentaire"
                                v-model="reglement.entite.personneRessource.commentaire" :readonly="readonly" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group col-sm-12">
            <label for="entite.courrielTransmission">
                Autre(s) adresse(s) courriel à ajouter lors de l'envoi (Séparer par des virgules)
            </label>
            <input type="email" class="form-control" id="entite.courrielTransmission" :readonly="readonly"
                v-model="reglement.entite.courrielTransmission" @change="onCourrielTransmissionChange"
                :class="!champsValides.courrielTransmission ? 'is-invalid' : ''" />
            <p class="text-danger" v-if="!champsValides.courrielTransmission">
                {{ messagesValidation.courrielTransmission }}
            </p>
        </div>
    </Card>
</template>
<style lang="scss" scoped>
input[type=number] {
    text-align: left;
}
</style>
<script lang="ts">
import Reglement, { TitreCivilite } from '../../reglement'
import { PropType, defineComponent } from 'vue'
import { validationsReglement } from '../../validate-reglement'
import * as messagesValidation from "../../messages/validation.json"
import Helpers from "../../helpers"
import Card from '../composants/card.vue'

export default defineComponent({
    props: {
        reglement: { type: Object as PropType<Reglement>, required: true },
        forcedOpened: { type: Boolean, required: true },
        readonly: { type: Boolean, required: true }
    },
    data() {
        return {
            TitreCivilite,
            fold: false,
            champsValides: {
                repondant: {
                    courriel: true
                },
                personneRessource: {
                    courriel: true,
                    telephone: true
                },
                courrielTransmission: true
            },
            messagesValidation: messagesValidation.entite,
            Helpers
        }
    },
    mounted() {
        this.onPersonneRessourceCourrielChange()
        this.onPersonneRessourceTelephoneChange()
        this.onCourrielTransmissionChange()
    },
    methods: {
        onPersonneRessourceMemeQueRepondantChange() {
            if (this.reglement.entite.personneRessource.memeQueRepondant) {
                this.reglement.entite.personneRessource.nomComplet = undefined
                this.reglement.entite.personneRessource.fonction = undefined
                this.reglement.entite.personneRessource.courriel = undefined
                this.reglement.entite.personneRessource.telephone = undefined
            }
        },
        onREpondantCourrielChange() {
            this.champsValides.repondant.courriel = validationsReglement.entite.repondant.courriel(this.reglement)
        },
        onPersonneRessourceCourrielChange() {
            this.champsValides.personneRessource.courriel = validationsReglement.entite.personneRessource.courriel(this.reglement)
        },
        onPersonneRessourceTelephoneChange() {
            this.champsValides.personneRessource.telephone = validationsReglement.entite.personneRessource.telephone(this.reglement)
        },
        onCourrielTransmissionChange() {
            this.champsValides.courrielTransmission = validationsReglement.entite.courrielTransmission(this.reglement)
        }
    },
    components: { Card }
})
</script>