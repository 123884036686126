import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dialog_confirm = _resolveComponent("dialog-confirm")!
  const _component_toast = _resolveComponent("toast")!
  const _component_grid = _resolveComponent("grid")!
  const _component_formulaire = _resolveComponent("formulaire")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_dialog_confirm, { params: _ctx.dialogParams }, null, 8 /* PROPS */, ["params"]),
    _createVNode(_component_toast, { params: _ctx.toastParams }, null, 8 /* PROPS */, ["params"]),
    (_ctx.currentTab === _ctx.Tab.Grid)
      ? (_openBlock(), _createBlock(_component_grid, {
          key: 0,
          setTab: _ctx.setTab,
          showDialog: _ctx.showDialog
        }, null, 8 /* PROPS */, ["setTab", "showDialog"]))
      : (_ctx.currentTab === _ctx.Tab.Formulaire)
        ? (_openBlock(), _createBlock(_component_formulaire, {
            key: 1,
            setTab: _ctx.setTab,
            "reglement-grid": _ctx.reglement,
            showDialog: _ctx.showDialog,
            showToast: _ctx.showToast
          }, null, 8 /* PROPS */, ["setTab", "reglement-grid", "showDialog", "showToast"]))
        : _createCommentVNode("v-if", true)
  ]))
}