<template>
    <Card title="Répartition fiscale" :forced-opened="forcedOpened">
        <div class="card">
            <h6 class="card-header">Détermination de l'annuité</h6>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="mnt-th">Montant</th>
                            <th class="mnt-th">Taux (%)</th>
                            <th class="mnt-th">Terme (années)</th>
                            <th class="mnt-th">Annuité ($)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="annuite in reglement.repartitionFiscale.annuite">
                            <td class="mnt">
                                <InputMontant :parent="annuite" champ="montant" :readonly="readonly"
                                    :on-input="calculerAnnuite(annuite)" />
                            </td>
                            <td class="num">
                                <InputMontant :parent="annuite" champ="taux" :readonly="readonly"
                                    :on-input="calculerAnnuite(annuite)" /> %
                            </td>
                            <td class="num">
                                <input type="number" v-model="annuite.terme" min="1" max="25" step="1" :readonly="readonly"
                                    class="form-control input-print-keep-margin" @change="calculerAnnuite(annuite)" />
                            </td>
                            <td class="mnt">
                                <InputMontant :parent="annuite" champ="annuite" readonly />
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th class="mnt total">
                                <input type="number" class="form-control" readonly
                                    :value="(reglement.repartitionFiscale.annuite.reduce((somme, montant) => somme += (montant.montant || 0), 0) || 0).toFixed(2)" />
                            </th>
                            <th></th>
                            <th></th>
                            <th class="mnt total">
                                <input type="number" class="form-control" readonly
                                    :value="(reglement.repartitionFiscale.annuite.reduce((somme, montant) => somme += (montant.annuite || 0), 0) || 0).toFixed(2)" />
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

        <div class="card">
            <h6 class="card-header">Fardeau fiscal de l'emprunt</h6>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Base d'imposition</th>
                            <th>Type de contribuables</th>
                            <th class="mnt-th">Montant</th>
                            <th class="mnt-th">Assiette totale imposable</th>
                            <th class="mnt-th">Unité moyenne imposable</th>
                            <th class="mnt-th">Charge fiscale</th>
                            <th>Précisions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="fardeauFiscalEmprunt in reglement.repartitionFiscale.fardeauFiscalEmprunt">
                            <td>
                                <select class="form-select" id="fardeauFiscalEmprunt.baseImposition"
                                    @change="ajouterLigneFardeauFiscalEmprunt" v-model="fardeauFiscalEmprunt.baseImposition"
                                    :disabled="readonly">
                                    <option v-for="baseImposition in BaseImposition" :value="baseImposition">{{
                                        baseImposition }}</option>
                                </select>
                            </td>
                            <td>
                                <select class="form-select" id="fardeauFiscalEmprunt.typeContribuable"
                                    @change="ajouterLigneFardeauFiscalEmprunt"
                                    v-model="fardeauFiscalEmprunt.typeContribuable" :disabled="readonly">
                                    <option v-for="typeContribuable in TypeContribuable" :value="typeContribuable">{{
                                        typeContribuable }}</option>
                                </select>
                            </td>
                            <td class="mnt">
                                <InputMontant :parent="fardeauFiscalEmprunt" champ="montant" :readonly="readonly"
                                    :on-input="calculerChargeFiscale(fardeauFiscalEmprunt)" />
                            </td>
                            <td class="num">
                                <InputMontant :parent="fardeauFiscalEmprunt" champ="assietteTotaleImposable"
                                    :readonly="readonly" :on-input="calculerChargeFiscale(fardeauFiscalEmprunt)" />
                            </td>
                            <td class="num">
                                <InputMontant :parent="fardeauFiscalEmprunt" champ="uniteMoyenneImposable"
                                    :readonly="readonly" :on-input="calculerChargeFiscale(fardeauFiscalEmprunt)" />
                            </td>
                            <td class="mnt">
                                <InputMontant :parent="fardeauFiscalEmprunt" champ="chargeFiscale" readonly />
                            </td>
                            <td>
                                {{ BaseImpositionUnitePrecision[fardeauFiscalEmprunt.baseImposition] }}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th></th>
                            <th></th>
                            <th class="mnt total">
                                <input type="number" class="form-control" readonly
                                    :value="(reglement.repartitionFiscale.fardeauFiscalEmprunt.reduce((somme, montant) => somme += (montant.montant || 0), 0) || 0).toFixed(2)" />
                            </th>
                            <th></th>
                            <th class="text-end">Total (A)</th>
                            <th class="mnt total">
                                <input type="number" class="form-control" readonly
                                    :value="reglement.repartitionFiscale.fardeauFiscalEmprunt.reduce((somme, montant) => somme += (montant.chargeFiscale || 0), 0).toFixed(2)" />
                            </th>
                            <th></th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

        <div class="card">
            <h6 class="card-header">Fardeau fiscal actuel</h6>
            <div class="card-body fardeau-fiscal-actuel-body">
                <div>
                    <div class="text-end">
                        <div class="d-inline-block">
                            <span>Taxe(s) générale(s) sur la valeur foncière</span>
                            <div class="form-group mnt">
                                <label for="repartitionFiscale.fardeauFiscalActuel.taxeGenerale.taux">Taux</label>
                                <InputMontant :parent="reglement.repartitionFiscale.fardeauFiscalActuel.taxeGenerale"
                                    id="repartitionFiscale.fardeauFiscalActuel.taxeGenerale.taux"
                                    :on-input="calculerFardeauActuelTaxe" champ="taux" :readonly="readonly" />
                            </div>
                            <span> /100$ x </span>
                            <div class="form-group mnt">
                                <label
                                    for="repartitionFiscale.fardeauFiscalActuel.taxeGenerale.evaluation">Évaluation</label>
                                <InputMontant :parent="reglement.repartitionFiscale.fardeauFiscalActuel.taxeGenerale"
                                    id="repartitionFiscale.fardeauFiscalActuel.taxeGenerale.evaluation"
                                    :on-input="calculerFardeauActuelTaxe" champ="evaluation" :readonly="readonly" />
                            </div>
                        </div>
                        <div class="d-inline-block">
                            <span>=</span>
                            <div class="form-group mnt">
                                <InputMontant class="mt-4"
                                    :parent="reglement.repartitionFiscale.fardeauFiscalActuel.taxeGenerale" champ="total"
                                    readonly />
                            </div>
                        </div>
                    </div>
                    <div class="text-end mt-3">
                        <div class="d-inline-block">
                            <span>Tarification</span>
                            <div class="form-group mnt">
                                <label for="repartitionFiscale.fardeauFiscalActuel.tarification.eau">Eau</label>
                                <InputMontant :parent="reglement.repartitionFiscale.fardeauFiscalActuel.tarification"
                                    id="repartitionFiscale.fardeauFiscalActuel.tarification.eau"
                                    :on-input="calculerFardeauActuelTarification" champ="eau" :readonly="readonly" />
                            </div>
                            <span>+</span>
                            <div class="form-group mnt">
                                <label for="repartitionFiscale.fardeauFiscalActuel.tarification.egouts">Égouts</label>
                                <InputMontant :parent="reglement.repartitionFiscale.fardeauFiscalActuel.tarification"
                                    id="repartitionFiscale.fardeauFiscalActuel.tarification.egouts"
                                    :on-input="calculerFardeauActuelTarification" champ="egouts" :readonly="readonly" />
                            </div>
                            <span>+</span>
                            <div class="form-group mnt">
                                <label for="repartitionFiscale.fardeauFiscalActuel.tarification.ordures">Ordures</label>
                                <InputMontant :parent="reglement.repartitionFiscale.fardeauFiscalActuel.tarification"
                                    id="repartitionFiscale.fardeauFiscalActuel.tarification.ordures"
                                    :on-input="calculerFardeauActuelTarification" champ="ordures" :readonly="readonly" />
                            </div>
                        </div>
                        <div class="d-inline-block">
                            <span>=</span>
                            <div class="form-group mnt">
                                <InputMontant class="mt-4"
                                    :parent="reglement.repartitionFiscale.fardeauFiscalActuel.tarification"
                                    id="repartitionFiscale.fardeauFiscalActuel.tarification.total" champ="total" readonly />
                            </div>
                        </div>
                    </div>
                    <div class="text-end mt-3">
                        <div class="d-inline-block">
                            <div class="form-group autres-taxes">
                                <label for="repartitionFiscale.fardeauFiscalActuel.autresTaxes.precision">Autres taxes,
                                    précisez</label>
                                <input type="text" class="form-control"
                                    id="repartitionFiscale.fardeauFiscalActuel.autresTaxes.precision"
                                    v-model="reglement.repartitionFiscale.fardeauFiscalActuel.autresTaxes.precision"
                                    :readonly="readonly" />
                            </div>
                        </div>
                        <div class="d-inline-block">
                            <span>=</span>
                            <div class="form-group mnt">
                                <InputMontant class="mt-4"
                                    :parent="reglement.repartitionFiscale.fardeauFiscalActuel.autresTaxes" champ="montant"
                                    id="epartitionFiscale.fardeauFiscalActuel.autresTaxes.montant"
                                    :on-input="calculerFardeauActuelTotal" :readonly="readonly" />
                            </div>
                        </div>
                    </div>
                    <div class="text-end">
                        <span class="fw-bold">Total (B)</span>
                        <div class="form-group mnt total">
                            <InputMontant class="mt-2" id="repartitionFiscale.fardeauFiscalActuel.total"
                                :parent="reglement.repartitionFiscale.fardeauFiscalActuel" champ="total" readonly />
                        </div>
                    </div>
                    <div class="text-end">
                        <span class="fw-bold">Fardeau fiscal total (A + B)</span>
                        <div class="form-group mnt total">
                            <InputMontant class="mt-2" id="repartitionFiscale.fardeauFiscalTotal"
                                :parent="reglement.repartitionFiscale" champ="fardeauFiscalTotal" readonly />
                        </div>
                    </div>
                    <div class="text-end">
                        <span class="fw-bold">Variation (%)</span>
                        <div class="form-group num total">
                            <InputMontant class="mt-2" id="repartitionFiscale.variationPourcentage"
                                :parent="reglement.repartitionFiscale" champ="variationPourcentage" readonly />%
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Card>
</template>
<style lang="scss" scoped>
span {
    padding-bottom: 0.3rem;
    padding-right: 1em;
    vertical-align: bottom;
    display: inline-block;
    margin-top: auto;
}

.autres-taxes {
    label {
        width: 100%;
        text-align: left;
    }

    input {
        width: 40em;
    }
}

.fardeau-fiscal-actuel-body>div {
    display: inline-block;
    vertical-align: top;
}
</style>
<script lang="ts">
import { PropType, defineComponent } from "vue"
import Reglement, { Annuite, BaseImposition, BaseImpositionUnitePrecision, FardeauFiscalEmprunt, TypeContribuable } from "../../reglement"
import Card from "../composants/card.vue"
import InputMontant from "../composants/input-montant.vue"

export default defineComponent({
    props: {
        reglement: { type: Object as PropType<Reglement>, required: true },
        forcedOpened: { type: Boolean, required: true },
        readonly: { type: Boolean, required: true }
    },
    data() {
        return {
            fold: false,
            champsValides: {},
            TypeContribuable,
            BaseImposition,
            BaseImpositionUnitePrecision
        }
    },
    components: { Card, InputMontant },
    methods: {
        calculerAnnuite(annuite: Annuite) {
            const taux = (annuite.taux || 0) / 100
            annuite.annuite = Math.ceil((annuite.montant || 0) * (taux / (1 - Math.pow(1 + taux, -(annuite.terme || 0)))))


            if (!this.reglement.repartitionFiscale.annuite.some((annuite: Annuite) => !annuite.montant && !annuite.taux && !annuite.terme)) {
                this.reglement.repartitionFiscale.annuite.push({})
            }
        },
        calculerChargeFiscale(fardeauFiscalEmprunt: FardeauFiscalEmprunt) {
            fardeauFiscalEmprunt.chargeFiscale = Number((fardeauFiscalEmprunt.assietteTotaleImposable === 0 ? 0 : fardeauFiscalEmprunt.montant / fardeauFiscalEmprunt.assietteTotaleImposable * fardeauFiscalEmprunt.uniteMoyenneImposable).toFixed(2)) || 0

            this.ajouterLigneFardeauFiscalEmprunt()
            this.calculerFardeauFiscalTotal()
        },
        ajouterLigneFardeauFiscalEmprunt() {
            if (!this.reglement.repartitionFiscale.fardeauFiscalEmprunt.some((fardeauFiscalEmprunt: FardeauFiscalEmprunt) => !fardeauFiscalEmprunt.baseImposition && !fardeauFiscalEmprunt.typeContribuable && !fardeauFiscalEmprunt.montant && !fardeauFiscalEmprunt.assietteTotaleImposable && !fardeauFiscalEmprunt.uniteMoyenneImposable)) {
                this.reglement.repartitionFiscale.fardeauFiscalEmprunt.push({})
            }
        },
        calculerFardeauActuelTaxe() {
            this.reglement.repartitionFiscale.fardeauFiscalActuel.taxeGenerale.total = (this.reglement.repartitionFiscale.fardeauFiscalActuel.taxeGenerale.taux || 0) / 100 * (this.reglement.repartitionFiscale.fardeauFiscalActuel.taxeGenerale.evaluation || 0)

            this.calculerFardeauActuelTotal()
        },
        calculerFardeauActuelTarification() {
            this.reglement.repartitionFiscale.fardeauFiscalActuel.tarification.total = (this.reglement.repartitionFiscale.fardeauFiscalActuel.tarification.eau || 0) + (this.reglement.repartitionFiscale.fardeauFiscalActuel.tarification.egouts || 0) + (this.reglement.repartitionFiscale.fardeauFiscalActuel.tarification.ordures || 0)

            this.calculerFardeauActuelTotal()
        },
        calculerFardeauActuelTotal() {
            this.reglement.repartitionFiscale.fardeauFiscalActuel.total = (this.reglement.repartitionFiscale.fardeauFiscalActuel.taxeGenerale.total || 0) + (this.reglement.repartitionFiscale.fardeauFiscalActuel.tarification.total || 0) + (this.reglement.repartitionFiscale.fardeauFiscalActuel.autresTaxes.montant || 0)

            this.calculerFardeauFiscalTotal()
        },
        calculerFardeauFiscalTotal() {
            this.reglement.repartitionFiscale.fardeauFiscalTotal = this.reglement.repartitionFiscale.fardeauFiscalActuel.total + this.reglement.repartitionFiscale.fardeauFiscalEmprunt.reduce((somme, montant) => somme += (montant.chargeFiscale || 0), 0)

            this.reglement.repartitionFiscale.variationPourcentage = (this.reglement.repartitionFiscale.fardeauFiscalTotal - this.reglement.repartitionFiscale.fardeauFiscalActuel.total) / this.reglement.repartitionFiscale.fardeauFiscalActuel.total * 100
        }
    }
})
</script>