<template>
    <div>
        <dialog-confirm :params="dialogParams" />
        <toast :params="toastParams" />
        <grid v-if="currentTab === Tab.Grid" :setTab="setTab" :showDialog="showDialog" />
        <formulaire v-else-if="currentTab === Tab.Formulaire" :setTab="setTab" :reglement-grid="reglement"
            :showDialog="showDialog" :showToast="showToast" />
    </div>
</template>
<script lang="ts">
import { defineComponent } from "vue"
import grid from "./vue/grid.vue"
import Tab from "./tab"
import formulaire from "./vue/formulaire.vue"
import dialogConfirm from "./vue/composants/dialog-confirm.vue"
import DialogParams, { DialogButtons, ToastParams } from "./dialog-params"
import Reglement from "./reglement"
import Helpers from "./helpers"
import Toast from "./vue/composants/toast.vue"

export default defineComponent({
    data() {
        return {
            currentId: null,
            currentTab: Tab.Grid,
            Tab,
            dialogParams: { show: false } as DialogParams,
            toastParams: { show: false } as ToastParams,
            reglement: {} as Reglement
        }
    },
    components: {
        grid,
        formulaire,
        dialogConfirm,
        Toast
    },
    mounted() {
        this.setTab(Tab.Grid, this.reglement)
    },
    methods: {
        setTab(tab: Tab, reglement: Reglement) {
            if (reglement) {
                Helpers.updateReglementMissingFields(reglement)
            }
            this.currentTab = tab
            this.reglement = reglement
        },
        showDialog(dialogParams: DialogParams) {
            dialogParams.show = true
            if (dialogParams.dialogButtons == null) {
                dialogParams.dialogButtons = DialogButtons.AnnulerContinuer
            }
            this.dialogParams = dialogParams
        },
        showToast(toastParams: ToastParams) {
            toastParams.show = true
            this.toastParams = toastParams
        }
    }
})
</script>